import {makeAutoObservable} from "mobx";
import {RecipeMode, RecipeStore} from "./RecipeStore";
import {RecipeSchema} from "../api";

export class RecipeListStore {
    constructor(init?: Partial<RecipeListStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    recipes: RecipeStore [] = [];

    inExpandMode: boolean = false;
    recipeCountWhenCollapsed: number = 3;

    get visibleRecipes() {
        if (!this.inExpandMode) {
            return this.recipes;
        }
        return this.isExpanded ? this.recipes : this.recipes.slice(0, this.recipeCountWhenCollapsed);
    }

    isExpanded: boolean = false;

    get hasItems(){
        return this.recipes.length > 0;
    }

    get hasMoreItems(){
        return this.recipes.length > this.recipeCountWhenCollapsed;
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    static fromDto(dto: RecipeSchema[]): RecipeListStore {
        const result = new RecipeListStore();
        result.loadFromDto(dto);
        return result;
    }

    loadFromDto(dto: RecipeSchema[]) {
        this.recipes = dto.map((recipeDto: any) => RecipeStore.fromDto(recipeDto, RecipeMode.Default));
    }
}
