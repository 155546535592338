export function humanizeNumber(number, precision = 1) {
    const alphabet = ['K', 'M', 'B', 'T']
    const threshold = 1e3

    let idx = 0
    while (number >= threshold && ++idx <= alphabet.length) {
        number /= threshold
    }
    const formattedNumber = number.toFixed(precision)
    return String(idx === 0 ? number : formattedNumber + alphabet[idx - 1])
}
