/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {CommentCreateSchema} from '../models/CommentCreateSchema';
import type {CommentListSchema} from '../models/CommentListSchema';
import type {CommentSchema} from '../models/CommentSchema';
import type {CommentUpdateSchema} from '../models/CommentUpdateSchema';
import type {CreateComfyRecipeRequest} from '../models/CreateComfyRecipeRequest';
import type {CreateComfyRecipeResponse} from '../models/CreateComfyRecipeResponse';
import type {DiagResponse} from '../models/DiagResponse';
import type {EmptyResponse} from '../models/EmptyResponse';
import type {FileUploadResultSchema} from '../models/FileUploadResultSchema';
import type {LogList} from '../models/LogList';
import type {RecipeDetailsSchema} from '../models/RecipeDetailsSchema';
import type {RecipeHighlightsSchema} from '../models/RecipeHighlightsSchema';
import type {RecipeSchema} from '../models/RecipeSchema';
import type {RunRecipeRequestSchema} from '../models/RunRecipeRequestSchema';
import type {ShareLinkSchema} from '../models/ShareLinkSchema';
import type {UpdateRecipeSchema} from '../models/UpdateRecipeSchema';
import type {UpdateUserSchema} from '../models/UpdateUserSchema';
import type {UserProfileSchema} from '../models/UserProfileSchema';
import type {VoteRequest} from '../models/VoteRequest';
import type {VoteResponse} from '../models/VoteResponse';
import type {WaitForCompletionResponseSchema} from '../models/WaitForCompletionResponseSchema';
import type {WebEvent} from '../models/WebEvent';
import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';

export class DefaultService {
    /**
     * Worker Log
     * @param workerName
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static youmlComfyApiWorkerLog(
        workerName: string,
        requestBody: LogList,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/comfy/workers/{worker_name}/log',
            path: {
                'worker_name': workerName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Diag Test Request
     * @returns DiagResponse OK
     * @throws ApiError
     */
    public static youmlComfyApiDiagTestRequest(): CancelablePromise<DiagResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/diag/test',
        });
    }
    /**
     * Create Recipe
     * @param requestBody
     * @returns CreateComfyRecipeResponse OK
     * @throws ApiError
     */
    public static youmlComfyApiCreateRecipe(
        requestBody: CreateComfyRecipeRequest,
    ): CancelablePromise<CreateComfyRecipeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comfy/recipes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Recipe Thumbnail
     * @param recipeId
     * @param formData
     * @returns EmptyResponse OK
     * @throws ApiError
     */
    public static youmlComfyApiUpdateRecipeThumbnail(
        recipeId: number,
        formData: {
            file: Blob;
        },
    ): CancelablePromise<EmptyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comfy/recipes/{recipe_id}/thumbnail',
            path: {
                'recipe_id': recipeId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get Recipe
     * @param recipeId
     * @param includeSettings
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipe(
        recipeId: number,
        includeSettings: boolean = false,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
            query: {
                'include_settings': includeSettings,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Patch Recipe
     * @param recipeId
     * @param requestBody
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPatchRecipe(
        recipeId: number,
        requestBody: UpdateRecipeSchema,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Recipe
     * @param recipeId
     * @returns any OK
     * @throws ApiError
     */
    public static youmlRecipesApiDeleteRecipe(
        recipeId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/recipes/{recipe_id}',
            path: {
                'recipe_id': recipeId,
            },
        });
    }
    /**
     * Get Recipe Details
     * @param recipeId
     * @returns RecipeDetailsSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipeDetails(
        recipeId: number,
    ): CancelablePromise<RecipeDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recipes/{recipe_id}/details',
            path: {
                'recipe_id': recipeId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Get Recipe Result
     * @param shareId
     * @param wait
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipeResult(
        shareId: string,
        wait?: number,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recipe-results/{share_id}',
            path: {
                'share_id': shareId,
            },
            query: {
                'wait': wait,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Get Recipe Result
     * @param shareId
     * @param wait
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipeResult1(
        shareId: string,
        wait?: number,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipe-results/{share_id}',
            path: {
                'share_id': shareId,
            },
            query: {
                'wait': wait,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Post Recipes Vote
     * @param recipeId
     * @param requestBody
     * @returns VoteResponse OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipesVote(
        recipeId: number,
        requestBody: VoteRequest,
    ): CancelablePromise<VoteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/vote',
            path: {
                'recipe_id': recipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Post Recipes Wait For Completion
     * @param recipeId
     * @param timeoutSeconds
     * @returns WaitForCompletionResponseSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipesWaitForCompletion(
        recipeId: number,
        timeoutSeconds: number,
    ): CancelablePromise<WaitForCompletionResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/wait-for-completion',
            path: {
                'recipe_id': recipeId,
            },
            query: {
                'timeout_seconds': timeoutSeconds,
            },
        });
    }
    /**
     * Post Recipes Run
     * @param recipeId
     * @param requestBody
     * @param wait
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipesRun(
        recipeId: number,
        requestBody: RunRecipeRequestSchema,
        wait?: (number | null),
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/run',
            path: {
                'recipe_id': recipeId,
            },
            query: {
                'wait': wait,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Post Recipes Create Draft
     * @param recipeId
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipesCreateDraft(
        recipeId: number,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/draft',
            path: {
                'recipe_id': recipeId,
            },
        });
    }
    /**
     * Post Recipes Publish Draft
     * @param recipeId
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipesPublishDraft(
        recipeId: number,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/publish',
            path: {
                'recipe_id': recipeId,
            },
        });
    }
    /**
     * Post Recipe Set As Template Thumbnail
     * @param recipeId
     * @returns RecipeSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiPostRecipeSetAsTemplateThumbnail(
        recipeId: number,
    ): CancelablePromise<RecipeSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/recipes/{recipe_id}/set-as-template-thumbnail',
            path: {
                'recipe_id': recipeId,
            },
        });
    }
    /**
     * Get Recipe Highlights
     * @returns RecipeHighlightsSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipeHighlights(): CancelablePromise<RecipeHighlightsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recipe-highlights',
        });
    }
    /**
     * Update Recipe Thumbnail
     * @param formData
     * @returns FileUploadResultSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiUpdateRecipeThumbnail(
        formData: {
            folder: 'recipe-inputs' | 'recipe-thumbnails';
            recipeId?: (number | null);
            file: Blob;
        },
    ): CancelablePromise<FileUploadResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Get Recipe Share Link
     * @param recipeId
     * @param shareId
     * @returns ShareLinkSchema OK
     * @throws ApiError
     */
    public static youmlRecipesApiGetRecipeShareLink(
        recipeId: number,
        shareId?: (string | null),
    ): CancelablePromise<ShareLinkSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/recipes/{recipe_id}/share-link',
            path: {
                'recipe_id': recipeId,
            },
            query: {
                'share_id': shareId,
            },
        });
    }
    /**
     * Log Web Event
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static youmlCommonApiLogWebEvent(
        requestBody: WebEvent,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/web-events',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get User Profile
     * @param userIdOrUsername
     * @returns UserProfileSchema OK
     * @throws ApiError
     */
    public static youmlUsersApiGetUserProfile(
        userIdOrUsername: (number | string),
    ): CancelablePromise<UserProfileSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{user_id_or_username}/profile',
            path: {
                'user_id_or_username': userIdOrUsername,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Patch Recipe
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static youmlUsersApiPatchRecipe(
        userId: number,
        requestBody: UpdateUserSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Comment List
     * @param objectType
     * @param objectId
     * @returns CommentListSchema OK
     * @throws ApiError
     */
    public static youmlCommentsApiGetCommentList(
        objectType: 'RECIPE' | 'MODEL',
        objectId: number,
    ): CancelablePromise<CommentListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/comments/{object_type}/{object_id}',
            path: {
                'object_type': objectType,
                'object_id': objectId,
            },
        });
    }
    /**
     * Post Comment
     * @param objectType
     * @param objectId
     * @param requestBody
     * @returns CommentSchema OK
     * @throws ApiError
     */
    public static youmlCommentsApiPostComment(
        objectType: 'RECIPE' | 'MODEL',
        objectId: number,
        requestBody: CommentCreateSchema,
    ): CancelablePromise<CommentSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comments/{object_type}/{object_id}',
            path: {
                'object_type': objectType,
                'object_id': objectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Patch Comment
     * @param objectType
     * @param objectId
     * @param commentId
     * @param requestBody
     * @returns CommentSchema OK
     * @throws ApiError
     */
    public static youmlCommentsApiPatchComment(
        objectType: 'RECIPE' | 'MODEL',
        objectId: number,
        commentId: number,
        requestBody: CommentUpdateSchema,
    ): CancelablePromise<CommentSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/comments/{object_type}/{object_id}/{comment_id}',
            path: {
                'object_type': objectType,
                'object_id': objectId,
                'comment_id': commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Comment
     * @param objectType
     * @param objectId
     * @param commentId
     * @returns any OK
     * @throws ApiError
     */
    public static youmlCommentsApiDeleteComment(
        objectType: 'RECIPE' | 'MODEL',
        objectId: number,
        commentId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/comments/{object_type}/{object_id}/{comment_id}',
            path: {
                'object_type': objectType,
                'object_id': objectId,
                'comment_id': commentId,
            },
        });
    }
}
