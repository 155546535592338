import "../youml/static/sass/project.scss";
import {addUppyControl} from "./components/Uploader";
import {ComfyUiService, Recipe} from "./services/ComfyUiService";
import {UploadService} from "./services/UploadService";
import {apiClient} from "./ApiClient";
import {RecipeService} from "./services/RecipeService";
import {
    renderComfyLogsButton,
    renderFrontPage,
    renderRecipeDetailsPage,
    renderRecipeResultPage,
    renderSimpleRecipeEditorPage,
    renderUserProfilePage
} from "./components/App";
import {logWebEvent} from "./utils/WebEventLogger";
import {joinDiscord, offerToJoinDiscord} from "./DiscordJoinHandler";
import {reportSignInResult} from "./components/SignInModalStore";
import {setCurrentUserContext} from "./AppContext";

import "./ErrorHandler"


// Root typescript file included in web app

declare var window: any;


window.addUppyControl = addUppyControl

let comfyUiManager: ComfyUiService;

function setupComfyWindow(frameName: string, recipe?: Recipe, comfyClientId?: string): ComfyUiService {
    const comfyFrame = window.document.getElementById(frameName) as HTMLIFrameElement

    if (comfyClientId) {
        comfyFrame.contentWindow.name = comfyClientId
    }
    comfyUiManager = new ComfyUiService(comfyFrame.contentWindow);

    if (recipe) {
        comfyUiManager.onComfyAppLoaded.subscribe(() => {
            comfyUiManager.loadRecipe(recipe);
        });
    }

    return comfyUiManager;
}

window.setupComfyWindow = setupComfyWindow;
window.uploadService = new UploadService();
window.apiClient = apiClient;
window.recipeService = new RecipeService();
window.renderFrontPage = renderFrontPage;
window.renderComfyLogsButton = renderComfyLogsButton;

window.renderRecipeResultPage = renderRecipeResultPage;
window.renderRecipeDetailsPage = renderRecipeDetailsPage;
window.renderSimpleRecipeEditorPage = renderSimpleRecipeEditorPage;

window.renderUserProfilePage = renderUserProfilePage;
window.reportSignInResult = reportSignInResult;
window.setCurrentUserContext = setCurrentUserContext;

window.logWebEvent = logWebEvent;


window.offerToJoinDiscord = offerToJoinDiscord;
window.joinDiscord = joinDiscord;

window.handleCommentClick = (page: string) => {
    logWebEvent("web.commentButton.click", "User clicked on comments button",
        {page: page})
        .then(() => {
        });

    window.open("https://discord.gg/d8mwjsqWrx", "_blank");
};

