import * as React from "react";
import {observer} from "mobx-react";
import {UserProfileStore} from "./UserProfileStore";
import Markdown from "react-markdown";
import {Button, ButtonGroup, Dropdown, DropdownButton, Modal, Tab, Tabs} from "react-bootstrap";
import {RecipeList} from "./RecipeList";
import {BonusCreditsDialog} from "./BonusCreditsDialog";
import {humanizeNumber} from "../utils/NumberFormatter";

const pluralize = require('pluralize');

export const ChangeUserNameModal = observer((props: { store: UserProfileStore }) => {
    const store = props.store;
    return (<Modal show={store.isChangeUsernameModalVisible}
                   onHide={() => store.hideChangeUsernameModal()}>
            <Modal.Header>
                <Modal.Title>
                    Change your User Name
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-2">
                    <label htmlFor="new-username" className="form-label">New User Name</label>
                    <input type="text"
                           id="new-username"
                           className="form-control"
                           value={store.newUsername}
                           onChange={(e) => store.setNewUsername(e.target.value)}
                           placeholder="New Username"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => store.changeUserName()}>
                    Change
                </Button>
                <Button variant="secondary" onClick={() => store.hideChangeUsernameModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
})


export const ChangeBioModal = observer((props: { store: UserProfileStore }) => {
    const store = props.store;
    return (<Modal show={store.isChangeBioModalVisible}
                   onHide={() => store.hideChangeBioModal()}
                   size="lg">
            <Modal.Header>
                <Modal.Title>
                    Change your Bio
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-2">
                    <label htmlFor="new-bio" className="form-label">Bio (markdown supported)</label>
                    <textarea id="new-bio"
                              className="form-control"
                              rows={8}
                              value={store.newBio}
                              onChange={(e) => store.setNewBio(e.target.value)}
                              placeholder="New Bio"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => store.changeBio()}>
                    Change
                </Button>
                <Button variant="secondary" onClick={() => store.hideChangeBioModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
})


export const UserProfile = observer((props: {
    store: UserProfileStore
}) => {
    const store = props.store;
    return (<div className="container user-profile-page mt-3">
        <div className="row justify-content-center">
            <div className="col-12 col-md-8">
                <div className="profile-header">
                    <h1>
                        <i className="fas fa-user-circle me-2"></i>
                        {store.username}
                    </h1>
                    {store.isMyProfile && <div className="settings-button">
                        <ButtonGroup>

                            <DropdownButton as={ButtonGroup} title={
                                <i className="fas fa-cog"></i>
                            } id="settings-dropdown"
                                            variant="secondary">
                                <Dropdown.Item eventKey="getApiKey" href="/my-token">
                                    Get API Token
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="changeUsername"
                                               onClick={() => store.showChangeUsernameModal()}>
                                    Change User Name
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="changeBio"
                                               onClick={() => store.showChangeBioModal()}>
                                    Change Bio
                                </Dropdown.Item>

                                <Dropdown.Item eventKey="comfyUIProjects"
                                               href="/comfy/projects">
                                    My Comfy UI Projects
                                </Dropdown.Item>

                                <Dropdown.Item eventKey="manageSubscription"
                                               href={"https://billing.stripe.com/p/login/00g15ggshaSQ5UcfYY?prefilled_email="
                                                   + encodeURIComponent(store.appContext?.currentUser?.email)}
                                               target="_blank">
                                    Manage My Subscription
                                </Dropdown.Item>

                                <Dropdown.Item eventKey="signOut"
                                               href="/accounts/logout/">
                                    Sign out
                                </Dropdown.Item>

                                <Dropdown.Divider/>
                                <Dropdown.Item eventKey="credits"
                                               onClick={() => store.bonusCreditsDialogStore.showDialog()}>
                                    {store.credits || "0"} Credits Remaining
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="referralCredits"
                                               onClick={() => store.bonusCreditsDialogStore.showDialog()}>
                                    {store.referralCredits || "0"} Credits From Referrals
                                </Dropdown.Item>

                            </DropdownButton>
                        </ButtonGroup>
                    </div>}
                </div>
                <div className="profile-stats text-muted mt-2">
                    <span title="Number of recipes created">
                        {store.totalRecipes || 0} {pluralize('recipe', store.totalRecipes)}
                    </span>
                    <span>‧</span>
                    <span title="How many time recipes were viewed">
                        {humanizeNumber(store.totalViews || 0)} {pluralize('view', store.totalViews)}
                    </span>
                    <span>‧</span>
                    <span title="How many time recipes were used">
                        {humanizeNumber(store.totalRuns || 0)} {pluralize('run', store.totalRuns)}
                    </span>
                    <span>‧</span>
                    <span title="How many time recipes were liked">
                        {humanizeNumber(store.totalUpvotes || 0)} {pluralize('like', store.totalUpvotes)}
                    </span>
                    <span>‧</span>
                    <span>
                        {store.totalCreationsFormatted} {pluralize('creation', store.totalCreations)}
                    </span>
                </div>
            </div>
        </div>

        <div className="row justify-content-center">
            <div className="col-12 col-md-6 d-flex justify-content-center">
                <div className="mt-3">
                    <Markdown>{store.bio}</Markdown>

                    {store.isMyProfile && !store.bio &&
                        <div className="text-muted text-center">
                            <Button variant="outline-secondary" size="sm" onClick={() => store.showChangeBioModal()}>
                                Add Bio
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-12 col-md-12">
                <div className="mt-4 recipe-tabs">
                    <Tabs className=" mb-3">
                        {store.publishedRecipes && store.publishedRecipes.hasItems &&
                            <Tab eventKey="recipes" title="Recipes">
                                {store.isMyProfile &&
                                    <div className="text-center text-muted">
                                        Unlisted and private recipes are visible only to you
                                    </div>}
                                <RecipeList store={store.publishedRecipes}/>
                            </Tab>
                        }

                        {store.creations && store.creations.hasItems &&
                            <Tab eventKey="creations" title="Creations">
                                {store.isMyProfile &&
                                    <div className="text-center text-muted">
                                        Unlisted and private creations are visible only to you
                                    </div>}
                                <RecipeList store={store.creations} minimalCards={true}/>
                            </Tab>
                        }

                        {store.unpublishedRecipes && store.unpublishedRecipes.hasItems &&
                            <Tab eventKey="drafts" title="Drafts">
                                {store.isMyProfile &&
                                    <div className="text-center text-muted">
                                        Drafts are visible only to you
                                    </div>}
                                <RecipeList store={store.unpublishedRecipes}/>
                            </Tab>
                        }
                    </Tabs>

                </div>
            </div>

        </div>

        <ChangeUserNameModal store={store}/>
        <ChangeBioModal store={store}/>
        <BonusCreditsDialog store={store.bonusCreditsDialogStore}/>
    </div>);
})

