import {makeAutoObservable} from "mobx";
import {RecipeMode, RecipeStore} from "./RecipeStore";
import {RecipeDetailsSchema} from "../api";
import {RecipeListStore} from "./RecipeListStore";

export class RecipeDetailsStore {
    constructor(init?: Partial<RecipeDetailsStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    recipe: RecipeStore;
    myCreations?: RecipeListStore;
    communityCreations?: RecipeListStore;
    featured?: RecipeListStore;


    static fromDto(dto: RecipeDetailsSchema, recipeMode: RecipeMode): RecipeDetailsStore {
        const result = new RecipeDetailsStore();
        result.loadFromDto(dto, recipeMode);
        return result;
    }

    loadFromDto(dto: RecipeDetailsSchema, recipeMode: RecipeMode): void {
        this.recipe = RecipeStore.fromDto(dto.recipe, recipeMode);

        if (dto.myCreations) {
            this.myCreations = RecipeListStore.fromDto(dto.myCreations);
            this.myCreations.inExpandMode = true;
        }

        if (dto.communityCreations) {
            this.communityCreations = RecipeListStore.fromDto(dto.communityCreations);
            this.communityCreations.inExpandMode = true;
        }

        if (dto.featured) {
            this.featured = RecipeListStore.fromDto(dto.featured);
            this.featured.inExpandMode = true;
        }
    }
}
