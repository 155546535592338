declare var gtag: any;

export enum EventName {
    SignIn = "sign-in",
}

export async function reportTrackingEvent(eventName: EventName): Promise<void> {
    // ad blockers can disable gtag, so we should not rely on it's request to complete
    if (typeof gtag !== 'undefined' && gtag) {
        if (eventName === EventName.SignIn) {
            gtag('event', 'conversion', {
                'send_to': 'AW-16454862792/Hwy_CIzJg5MZEMiPpaY9',
            });
        }

    }
}
