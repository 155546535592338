/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SimpleRecipeKind {
    IMAGE_TO_TEXT_TO_IMAGE = 'IMAGE_TO_TEXT_TO_IMAGE',
    TEXT_TO_TEXT = 'TEXT_TO_TEXT',
    TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
    IMAGE_TO_TEXT = 'IMAGE_TO_TEXT',
}
