import {makeAutoObservable} from "mobx";
import {appContext, AppContextStore, UserInfo} from "../AppContext";
import {formatDistance} from "date-fns";
import {CommentCreateSchema, CommentObjectType, CommentSchema, CommentUpdateSchema} from "../api";
import {apiClient} from "../ApiClient";


export enum CommentMode {
    View,
    Edit,
    Create
}

export class CommentStore {
    constructor(init?: Partial<CommentStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }

    }

    appContext: AppContextStore = appContext;

    id: number;
    owner: UserInfo;

    objectId: number;
    objectType: CommentObjectType;

    created: Date;

    message: string;
    editedMessage: string;
    wasEdited: boolean;

    mode: CommentMode = CommentMode.View;

    isDeleted: boolean = false;
    isOwner: boolean = false;

    get canEdit(): boolean {
        return this.isOwner || this.appContext.currentUser?.isSuperuser;
    }

    setEditedMessage(message: string) {
        this.editedMessage = message;
    }

    get createdFormatted(): string {
        return formatDistance(this.created, new Date(), {addSuffix: true})
    }

    async edit() {
        this.editedMessage = this.message;
        this.mode = CommentMode.Edit;
    }

    async saveEdit() {
        await apiClient.patch(`/api/v1/comments/${this.objectType.toLowerCase()}/${this.objectId}/${this.id}`, {
            message: this.editedMessage
        } as CommentUpdateSchema);

        this.message = this.editedMessage;
        this.mode = CommentMode.View;
        this.wasEdited = true;
    }

    cancelEdit() {
        this.mode = CommentMode.View;
    }

    async delete() {
        if (!confirm("Are you sure you want to delete this comment?")) {
            return;
        }

        await apiClient.delete(`/api/v1/comments/${this.objectType.toLowerCase()}/${this.objectId}/${this.id}`);
        this.isDeleted = true;
    }

    clear() {
        this.message = "";
        this.editedMessage = "";
    }

    async createComment() {
        if (!await this.appContext.ensureSignedIn()) {
            return;
        }

        const dto = await apiClient.post<CommentSchema>(`/api/v1/comments/${this.objectType.toLowerCase()}/${this.objectId}`,
            {
                message: this.editedMessage
            } as CommentCreateSchema);

        const newComment = CommentStore.fromDto(dto);
        if (this.onCommentCreated) {
            this.onCommentCreated(newComment);
        }

        this.clear();
    }

    onCommentCreated?: (newComment: CommentStore) => void;

    static fromDto(dto: CommentSchema): CommentStore {
        const store = new CommentStore();
        store.loadFromDto(dto);
        return store;
    }

    loadFromDto(dto: CommentSchema) {
        this.id = dto.id;
        this.owner = UserInfo.fromDto(dto.owner);
        this.created = new Date(dto.created);
        this.message = dto.message;
        this.wasEdited = dto.wasEdited;
        this.objectId = dto.objectId;
        this.objectType = dto.objectType;

        if (this.appContext.currentUser) {
            this.isOwner = appContext.currentUser.id === this.owner.id;
        }
    }
}
