import {RefObject, useEffect, useRef, useState} from "react";

export function useOnScreen(ref: RefObject<HTMLElement>): boolean {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) =>
            setIsOnScreen(entry.isIntersecting)
        );
    }, []);

    useEffect(() => {
        if (ref.current) {
            observerRef.current.observe(ref.current);
            return () => {
                observerRef.current.disconnect();
            };
        }
    }, [ref.current]);

    return isOnScreen;
}
