import {apiClient} from "../ApiClient";

export class RecipeProvisioningState {
    message: string;
    state: "QUEUED" | "RUNNING" | "READY" | "FAILED";
}

export class RecipeService {
    async getProvisioningState(recipeId: number): Promise<RecipeProvisioningState> {
        return await apiClient.get(`/api/comfy/v1/recipes/${recipeId}/provisioning-state`, RecipeProvisioningState)
    }
}
