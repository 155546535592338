import * as React from "react";
import {observer} from "mobx-react";
import {CommentListStore} from "./CommentListStore";
import {Comment} from "./Comment";


export const CommentList = observer((props: { store: CommentListStore }) => {
    const store = props.store;
    return (<div className="w-100 comment-list">
        <Comment store={store.newComment}/>

        {store.visibleComments.map((comment, index) =>
            <Comment key={index} store={comment}/>
        )}

        {store.hasMoreComments &&
            <div className="ms-4 ps-1">
                <button className="btn btn-secondary mx-3 py-1 rounded-5 expand-button"
                        onClick={() => store.toggleExpanded()}>
                    Show {store.isExpanded ? "less" : "more"}
                </button>
            </div>
        }
    </div>);
})

