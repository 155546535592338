import * as React from "react";
import {observer} from "mobx-react";
import {BonusCreditsDialogStore} from "./BonusCreditsDialogStore";
import {Modal} from "react-bootstrap";


export const BonusCreditsDialog = observer((props: { store: BonusCreditsDialogStore }) => {
    const store = props.store;
    return (<Modal show={store.isDialogVisible}
                   onHide={() => store.hideDialog()}
                   className="bonus-credits-dialog"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    Get More Credits
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-2">
                    <p>Get more credits to run recipes. One run is 50 credits.</p>

                    <p>
                        <span className="credit-value">+500 credits</span>: Share a recipe or art using the "share"
                        button.
                        Get a bonus for every new user that comes from your link.
                    </p>
                    <p>
                        <span className="credit-value">+500 credits</span>:
                        Share your <a href={store.profilePage} target="_blank">
                        profile page <i className="fas fa-external-link-alt"></i>
                    </a>&nbsp;
                        Get a bonus for every new user that joins after visiting your page.
                    </p>
                    <p>
                        <span className="credit-value">+500 credits</span>: Create or remix a recipe. One-time bonus.
                    </p>

                    <h6 className="text-center">
                        Or, buy credits
                    </h6>

                    <div className="d-flex flex-row flex-wrap gap-4 justify-content-center">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Starter</h5>
                                <p className="card-text">
                                    5,000 credits/month<br/>
                                    (100 runs)<br/>
                                    <span className="text-muted">$</span>4.95
                                </p>
                                <a href={"https://buy.stripe.com/00gbM37cEgzK0qkbIK?prefilled_email=" + encodeURIComponent(store.appContext?.currentUser?.email)}
                                   target="_blank" className="btn btn-primary">
                                    Buy Starter
                                </a>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Pro</h5>
                                <p className="card-text">
                                    25,000 credits/month<br/>
                                    (500 runs)<br/>
                                    <span className="text-muted">$</span>19.95
                                </p>
                                <a href={"https://buy.stripe.com/5kA5nF9kM6Zac92dQT?prefilled_email=" + encodeURIComponent(store.appContext?.currentUser?.email)}
                                   target="_blank"
                                   className="btn btn-primary">
                                    Buy Pro
                                </a>
                            </div>
                        </div>
                    </div>


                    <p className="text-muted text-center mt-2">To see your credits, click on gear on your profile
                        page</p>


                </div>
            </Modal.Body>
        </Modal>
    )
        ;
})

