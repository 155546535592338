import * as React from "react";
import {observer} from "mobx-react";
import {FrontPageStore, FrontPageVariant} from "./FrontPageStore";
import {RecipeList} from "./RecipeList";
import {RecipeCard} from "./RecipeCard";

import astronaut from "../../youml/static/images/astronaut.png";
import {humanizeNumber} from "../utils/NumberFormatter";


const ContestBlock = observer((props: { store: FrontPageStore }) => {
    return (<div className="contest-block mb-3">
        <div className="container py-5">
            <div className="row justify-content-center mt-5">
                <div className="col col-lg-8">
                    <h2 className="contest-header display-6 mx-2">ComfyUI Interactive Workflows Contest</h2>
                    <a className="btn btn-primary shadow-lg mx-2" href="/contest"><i className="fas fa-trophy me-2"></i>See
                        Details</a>
                </div>
            </div>
        </div>
    </div>);
})


const ComfyFeaturesBlock = observer((props: { store: FrontPageStore }) => {
    return (<div className="container my-5 py-3">
            <div className="row justify-content-center">
                <div className="col col-lg-8">
                    <h2 className="display-6 text-center">
                        YouML Features for Creators
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-12 col-md-6 col-lg-5">
                    <h2 className="text-center">
                        <i className="fas fa-sparkles"></i><br/>
                        ComfyUI Cloud Editor
                    </h2>
                    <p>
                        Edit and run your workflows on RTX4090 &amp; A10G GPU in the cloud.<br/>Free during beta.
                    </p>
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                    <h2 className="mx-2 text-center">
                        <i className="fas fa-cloud-download"></i><br/>
                        Automatic Model Downloads
                    </h2>
                    <p>
                        YouML will automatically download custom models and nodes, so you can try new workflows faster.
                        You can quickly download new models from popular sites.
                    </p>
                </div>
            </div>

            <div className="row justify-content-center mt-2">
                <div className="col-12 col-md-6 col-lg-5">
                    <h2 className="mx-2 text-center">
                        <i className="fas fa-random"></i><br/>
                        Remix
                    </h2>
                    <p>
                        Find a workflow that you like and remix it.
                        This will create a copy that you can change in the online editor,
                        all models and nodes will be downloaded automatically for you.
                        Want to edit on a local computer? Just download the workflow.
                    </p>
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                    <h2 className="mx-2 text-center">
                        <i className="far fa-sliders-h"></i><br/>
                        Interactive Apps
                    </h2>
                    <p>
                        Publish your workflow on YouML, and other people can run it for free without using ComfyUI.
                        Add parameters, and they can get unique personalized results every time.
                    </p>
                </div>
            </div>

        </div>
    );
})


const TopCreatorsBlock = observer((props: { store: FrontPageStore }) => {
    const store = props.store;
    return (<div className="container my-5 py-3">
            <div className="row justify-content-center">
                <div className="col col-lg-8">
                    <h2 className="text-center">
                        Top Creators This Week
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-md-6 col-lg-4">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Creator</th>
                            <th scope="col">Recipe Runs</th>
                            <th scope="col">Recipe Views</th>
                        </tr>
                        </thead>
                        <tbody>
                        {store.topCreators.map((creator, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <a href={creator.user.profileUrl} className="nav-link">
                                            <i className="fas fa-user-circle me-2"></i>
                                            {creator.user.username}
                                        </a>

                                    </td>
                                    <td>{humanizeNumber(creator.totalRuns)}</td>
                                    <td>{humanizeNumber(creator.totalViews)}</td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
})


export const FrontPage = observer((props: { store: FrontPageStore }) => {
    const store = props.store;
    return (<div className="front-page pb-5">
        <div className="mt-4"></div>

        <div className="container mb-5">
            <div className="row justify-content-center g-3 g-md-5">
                <div className="col col-sm-6 col-lg-5">
                    <h1 className="display-5 fw-bold lh-1 mb-3 mt-lg-5 mx-2">
                        {store.variant === FrontPageVariant.ComfyUIShare &&
                            <span>Share & Discover ComfyUI workflows</span>
                        }
                        {store.variant === FrontPageVariant.Default &&
                            <span>Discover & Create<br/>AI Art</span>
                        }

                    </h1>
                    <p className="lead mx-2">
                        {store.variant === FrontPageVariant.ComfyUIShare &&
                            <span>
                                Share your Comfy UI workflows as mini-apps that anyone can run for
                                free. <a href="/comfy-cloud-editor">Learn more</a>
                            </span>
                        }
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mx-2 mt-5">
                        <a className="btn btn-primary btn-lg px-4 me-md-2" href="/recipes">
                            <i className="far fa-rocket-launch me-2"></i>Discover
                        </a>
                        <a className="btn btn-outline-secondary btn-lg px-4" href="/recipes/create">
                            <i className="fas fa-plus me-2"></i>Create
                        </a>
                    </div>
                </div>
                <div className="col col-sm-6 col-lg-3">
                    {store.heroRecipe &&
                        <div className="d-flex justify-content-center float-md-end hero-recipe ">
                            <RecipeCard store={store.heroRecipe}/>
                        </div>
                    }
                </div>
            </div>

            {store.variant === FrontPageVariant.ComfyUIShare &&
                <>
                    <div className="row justify-content-center mt-4">
                        <div className="col-sm-12 col-lg-5">
                            <h2 className="mb-md-0 ms-3 text-center text-md-start">Popular ComfyUI Recipes</h2>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="ms-3 float-md-end me-3">
                                <a href="/recipes" className="btn btn-outline-secondary btn-block w-100">
                                    <i className="fas fa-arrow-right me-2 align-middle"></i>View All
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col col-lg-8">
                            <RecipeList store={store.trendingComfyRecipes} justifyRecipes={true}/>
                        </div>
                    </div>
                </>
            }


            <div className="row justify-content-center mt-4">
                <div className="col-sm-12 col-lg-5">
                    <h2 className="mb-md-0 ms-3 text-center text-md-start">Trending Recipes</h2>
                </div>
                <div className="col-sm-12 col-lg-3">
                    <div className="ms-3 float-md-end me-3">
                        <a href="/recipes" className="btn btn-outline-secondary btn-block w-100">
                            <i className="fas fa-arrow-right me-2 align-middle"></i>View All
                        </a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col col-lg-8">
                    <RecipeList store={store.trendingRecipes} justifyRecipes={true}/>
                </div>
            </div>


            <div className="row justify-content-center mt-5">
                <div className="col-sm-12 col-lg-5">
                    <h2 className="mb-md-0 ms-3 text-center text-md-start">Newest Recipes</h2>
                </div>
                <div className="col-sm-12 col-lg-3">
                    <div className="ms-3 float-md-end me-3">
                        <a href="/recipes" className="btn btn-outline-secondary btn-block w-100">
                            <i className="fas fa-arrow-right me-2 align-middle"></i>View All
                        </a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col col-lg-8">
                    <RecipeList store={store.newRecipes} justifyRecipes={true}/>
                </div>
            </div>

         {/*   {store.variant === FrontPageVariant.ComfyUIShare &&
                <div className="row justify-content-center mt-5">
                    <div className="col-6 col-lg-4 ms-3">
                        <h3>New ComfyUI Nodes</h3>
                        <ul>
                            <li><a href="https://github.com/jordoh/ComfyUI-Deepface" target="blank"
                                   className="nav-link">ComfyUI Deepface</a></li>
                            <li><a href="https://github.com/CaptainGrock/ComfyUIInvisibleWatermark" target="blank"
                                   className="nav-link">ComfyUIInvisibleWatermark</a></li>
                            <li><a href="https://github.com/SadaleNet/CLIPTextEncodeA1111-ComfyUI" target="blank"
                                   className="nav-link">ComfyUI A1111-like Prompt Custom Node Solution</a></li>
                            <li><a href="https://github.com/throttlekitty/SDXLCustomAspectRatio" target="blank"
                                   className="nav-link">SDXLCustomAspectRatio</a></li>
                            <li><a href="https://github.com/lordgasmic/ComfyUI-Wildcards" target="blank"
                                   className="nav-link">Wildcards</a></li>
                        </ul>
                        <a href="/a/custom-nodes" className="btn btn-secondary">
                            <i className="fas fa-arrow-right me-2 align-middle"></i> Explore More Custom Nodes
                        </a>
                    </div>

                    <div className="col-6 col-lg-4">
                        <h3>New Models</h3>
                        <ul>
                            <li><a href="https://huggingface.co/InstantX/InstantID" target="blank"
                                   className="nav-link">instantid/diffusion_pytorch_model.safetensors</a></li>
                            <li><a href="https://huggingface.co/InstantX/InstantID" target="blank"
                                   className="nav-link">instantid/ip-adapter.bin</a></li>
                            <li><a href="https://github.com/cubiq/ComfyUI_InstantID" target="blank"
                                   className="nav-link">insightface/models/antelopev2</a></li>
                            <li><a href="https://huggingface.co/TencentARC/PhotoMaker" target="blank"
                                   className="nav-link">photomaker</a></li>
                            <li><a href="https://github.com/xinntao/facexlib" target="blank"
                                   className="nav-link">facedetection/facexlib</a></li>
                        </ul>
                        <a href="/a/models" className="btn btn-secondary">
                            <i className="fas fa-arrow-right me-2 align-middle"></i> Explore More Models
                        </a>
                    </div>

                </div>}*/}
        </div>


        {/*<ContestBlock store={store}/>*/}

        <TopCreatorsBlock store={store}/>

        {store.variant === FrontPageVariant.ComfyUIShare &&
            <ComfyFeaturesBlock store={store}/>}

        <div className="container my-5">
            <div className="row justify-content-center mt-5 g-3 g-md-5">
                <div className="col-12 col-md-3">
                    <img src={astronaut}/>
                </div>
                <div className="col-12 col-md-3">
                    <h2>Join Our Digest</h2>
                    <p>
                        Sign-in to join our digest and get exclusive promotions & updates straight to your inbox.
                    </p>

                    <div className="socialaccount_ballot">
                        <ul className="socialaccount_providers m-0">
                            <li>
                                <a title="Google" className="socialaccount_provider google"
                                   href="/accounts/google/login/?process=login&next=/">
                                    Sign in with Google
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>


    </div>);
})

