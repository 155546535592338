import * as React from "react";
import {observer} from "mobx-react";
import {CommentMode, CommentStore} from "./CommentStore";
import Markdown from "react-markdown";
import {Button, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";

const classNames = require("classnames");

export const Comment = observer((props: { store: CommentStore }) => {
    const store = props.store;
    return (<div className={classNames({
            "comment": true,
            "comment-edit": store.mode === CommentMode.Edit,
            "comment-create": store.mode === CommentMode.Create,
            "comment-view": store.mode === CommentMode.View,
        })}>
            <div className="comment-logo">
                <i className="fas fa-user-circle me-2"></i>
            </div>
            <div className="comment-body">
                {store.mode === CommentMode.View && <>
                    <div className="comment-info">
                        <div className="username">
                            <a href={store.owner.profileUrl} className="nav-link">
                                @{store.owner.username}
                            </a>
                        </div>

                        <div className="text-muted">{store.createdFormatted}</div>
                        {store.wasEdited && <div className="text-muted">(edited)</div>}
                    </div>

                    <div className="comment-message">
                        <Markdown>{store.message}</Markdown>
                    </div>
                </>}

                {(store.mode === CommentMode.Edit || store.mode === CommentMode.Create) && <>
                <textarea className="form-control"
                          value={store.editedMessage}
                          placeholder="Add a comment..."
                          rows={1}
                          onChange={(e) => store.setEditedMessage(e.target.value)}/>
                </>}

            </div>

            {store.mode === CommentMode.View && store.canEdit &&
                <div className="comment-buttons">
                    <ButtonGroup>
                        <Button variant="secondary" className="px-3" onClick={() => store.edit()}>
                            <i className="fas fa-edit"></i>
                        </Button>
                        <DropdownButton as={ButtonGroup} title="" variant="secondary">
                            <Dropdown.Item eventKey="delete" onClick={() => store.delete()}>
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </div>
            }

            {store.mode === CommentMode.Edit &&
                <div className="comment-buttons">
                    <Button variant="primary" className="px-3" onClick={() => store.saveEdit()}>
                        Save
                    </Button>
                    <Button variant="secondary" className="px-3" onClick={() => store.cancelEdit()}>
                        Cancel
                    </Button>
                </div>
            }

            {store.mode === CommentMode.Create && store.editedMessage &&
                <div className="comment-buttons">
                    <Button variant="primary" className="px-3" onClick={() => store.createComment()}>
                        Post
                    </Button>
                </div>
            }
        </div>
    );
})

