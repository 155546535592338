import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import {RecipeMode, RecipeStore} from "./RecipeStore";
import {FrontPageStore} from "./FrontPageStore";
import {FrontPage} from "./FrontPage";
import {ComfyProjectLogViewerStore} from "./ComfyProjectLogViewerStore";
import {ComfyProjectLogViewerButton} from "./ComfyProjectLogViewer";
import {Page} from "./Page";
import {RecipeResult} from "./RecipeResult";
import {RecipeDetailsSchema, RecipeSchema, UserProfileSchema} from "../api";
import {RecipeDetails} from "./RecipeDetails";
import {RecipeDetailsStore} from "./RecipeDetailsStore";
import {UserProfileStore} from "./UserProfileStore";
import {UserProfile} from "./UserProfile";
import {SimpleRecipeEditor} from "./SimpleRecipeEditor";

export function renderFrontPage(frontPageDto: any) {
    const rootElement = document.getElementById("frontPageRoot");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new FrontPageStore();
    store.loadFromDto(frontPageDto);
    root.render(<Page frontNavBar={true}><FrontPage store={store}/></Page>);
}

export function renderComfyLogsButton(projectId: number) {
    const rootElement = document.getElementById("comfyLogsButtonRoot");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new ComfyProjectLogViewerStore();
    store.projectId = projectId;
    store.startListening();
    root.render(<ComfyProjectLogViewerButton store={store}/>);
}


export function renderRecipeResultPage(recipeDto: RecipeSchema) {
    const rootElement = document.getElementById("root");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new RecipeStore();
    store.loadFromDto(recipeDto, RecipeMode.ResultView);
    root.render(<Page hideNavBar={true}><RecipeResult store={store}/></Page>);
}

export function renderRecipeDetailsPage(recipeDto: RecipeDetailsSchema, mode: "api" | "default") {
    const rootElement = document.getElementById("root");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new RecipeDetailsStore();

    let recipeMode = RecipeMode.Details;

    if (mode === "api") {
        recipeMode = RecipeMode.ApiDetails;
    }

    store.loadFromDto(recipeDto, recipeMode);
    root.render(<Page><RecipeDetails store={store}/></Page>);
}

export function renderSimpleRecipeEditorPage(recipeDto: RecipeSchema) {
    const rootElement = document.getElementById("root");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new RecipeStore();
    store.loadFromDto(recipeDto, RecipeMode.Edit);
    root.render(<Page><SimpleRecipeEditor store={store}/></Page>);
}


export function renderUserProfilePage(userProfileDto: UserProfileSchema) {
    const rootElement = document.getElementById("root");
    const root = ReactDOMClient.createRoot(rootElement);
    const store = new UserProfileStore();
    store.loadFromDto(userProfileDto);
    root.render(<Page><UserProfile store={store}/></Page>);
}

