import * as React from "react";
import {observer} from "mobx-react";
import {RecipeStore} from "./RecipeStore";
import {humanizeNumber} from "../utils/NumberFormatter";

declare module "react" {
    interface HTMLAttributes<T> extends React.AriaAttributes, React.DOMAttributes<T> {
        fetchpriority?: 'high' | 'low' | 'auto';
    }
}


export const RecipeCard = observer((props: { store: RecipeStore, minimal?: boolean }) => {
    const store = props.store;
    return (<div className="recipe-list-card-wrapper">
        <a className="link-no-decoration" href={store.url}>
            <div className="card recipe-list-card">
                {store.isDraft && !props.minimal &&
                    <div className="draft-badge">
                        <span className="badge bg-light me-1">Draft</span>
                    </div>
                }
                <div
                    className={"recipe-card-thumbnail-container " + (store.animatedThumbnailUrl ? "recipe-card-with-animation " : "")}>
                    {store.thumbnailUrl &&
                        <img src={store.thumbnailUrl}
                             className="card-img-top recipe-card-thumbnail recipe-card-thumbnail-static"
                             alt={store.name}/>
                    }
                    <div className="animated-thumbnail-overlay">
                        <i className="fas fa-play"></i>
                    </div>

                    {store.animatedThumbnailUrl &&
                        <img src={store.animatedThumbnailUrl}
                             className="card-img-top recipe-card-thumbnail recipe-card-thumbnail-animated"
                             fetchpriority="low"
                             alt={store.name}/>
                    }
                    {!props.minimal &&
                        <div className="recipe-tags-container">
                            {store.isComfyRecipe &&
                                <div className="badge bg-light me-1 recipe-tag">
                                    ComfyUi
                                </div>
                            }
                        </div>}
                </div>
                {!props.minimal &&
                    <div className="card-body">
                        <div className="card-title">
                        <span>
                            {store.name || "[Unnamed recipe]"}
                        </span>
                        </div>

                        <div className="card-footer text-secondary">
                            <div className="d-flex">
                                <div className="username flex-grow-1">
                                    {store.owner.username}
                                </div>
                                <div className="view-count text-center">
                                    {store.viewCount > 0 &&
                                        <>
                                            <i className="far fa-eye recipe-card-stat-icon "></i>
                                            {humanizeNumber(store.viewCount)}
                                        </>
                                    }
                                </div>
                                <div className="upvote-percentage">
                                    {store.displayUpvotePercentage &&
                                        <>
                                            <i className="far fa-thumbs-up recipe-card-stat-icon ms-2"></i>
                                            {store.displayUpvotePercentage}%
                                        </>
                                    }
                                </div>

                                <div className="run-count text-center">
                                    {store.runCount > 0 &&
                                        <>
                                            <i className="far fa-play recipe-card-stat-icon ms-2"></i>
                                            {humanizeNumber(store.runCount)}
                                        </>
                                    }
                                </div>

                                <div className="run-button-pill">
                                    Run
                                </div>
                            </div>


                        </div>
                    </div>
                }
            </div>
        </a>

    </div>);
})

