import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import ImageEditor from "@uppy/image-editor";
import DropTarget from "@uppy/drop-target";
import AwsS3 from "@uppy/aws-s3";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import Form from "@uppy/form";

export interface UppyUploaderConfig {
    targetDivId: string;
    formId: string;
    resultFieldId: string;
    dropTarget: string | Element;
    uploadUrlTemplate: string;
}

const formUppyControlsCount: Map<string, number> = new Map();
const formUppyControlsUploaded: Map<string, number> = new Map();

export function addUppyControl(config: UppyUploaderConfig): Uppy {

    const form = window.document.getElementById(config.formId);
    if (!form) {
        throw new Error("Form with id " + config.formId + " not found");
    }

    if (formUppyControlsCount.has(config.formId)) {
        const count = formUppyControlsCount.get(config.formId);
        formUppyControlsCount.set(config.formId, count + 1);
    } else {
        formUppyControlsCount.set(config.formId, 1);
    }

    form.addEventListener("submit", (event) => {
        formUppyControlsUploaded.set(config.formId, 0);
    });

    const uppy = new Uppy(
        {
            restrictions: {
                minNumberOfFiles: 1,
                maxNumberOfFiles: 1,
                allowedFileTypes: ["image/*"],
                maxFileSize: 10 * 1024 * 1024
            },
            onBeforeUpload: (files) => {
                if (Object.keys(files).length < 1) {
                    alert("Please select an image first!");
                    return false
                }
            }
        })
        .use(Dashboard, {
            inline: true,
            target: "#" + config.targetDivId,
            proudlyDisplayPoweredByUppy: false,
            theme: "dark",
            hideUploadButton: true,
            hideCancelButton: true,
            disableStatusBar: true,
            autoOpenFileEditor: true,
            width: "500px",
            height: "400px",
        })
        .use(Webcam, {
            target: Dashboard,
            showVideoSourceDropdown: false,
            showRecordingLength: true,
            modes: ["picture"]
        })
        .use(ImageEditor, {target: Dashboard})
        .use(DropTarget, {
            target: config.dropTarget
        })
        .use(Form, {
            target: "#" + config.formId,
            getMetaFromForm: false,
            addResultToForm: true,
            resultName: config.resultFieldId,
            triggerUploadOnSubmit: true,
            submitOnSuccess: false
        });

    uppy.use(AwsS3, {
        getUploadParameters: async (file) => {

            const fileUrl = config.uploadUrlTemplate.replaceAll("{filename}", file.name);
            return {
                method: 'PUT',
                url: fileUrl,
                fields: {},
                headers: {
                    'x-ms-blob-type': 'BlockBlob'
                }
            }
        }
    })

    uppy.on("complete", (result) => {
        if (result.failed.length === 0) {
            console.log("Upload successful");

            const expectedUploadCount = formUppyControlsCount.get(config.formId);
            let actualUploadCount = formUppyControlsUploaded.get(config.formId);
            actualUploadCount++;
            formUppyControlsUploaded.set(config.formId, actualUploadCount);
            if (expectedUploadCount === actualUploadCount) {
                const form = window.document.getElementById(config.formId) as HTMLFormElement;
                form.submit();
                console.log("Form submitted");
            } else {
                console.log("Waiting for other uploads. Expected: " + expectedUploadCount + " Actual: " + actualUploadCount);
            }

        } else {
            console.warn("Upload failed");
        }
    });

    return uppy;
}
