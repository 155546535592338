import {makeAutoObservable} from "mobx";
import {appContext, AppContextStore} from "../AppContext";
import {RecipeListStore} from "./RecipeListStore";
import {UpdateUserSchema, UserProfileSchema} from "../api";
import {apiClient} from "../ApiClient";
import {BonusCreditsDialogStore} from "./BonusCreditsDialogStore";

export class UserProfileStore {

    constructor(init?: Partial<UserProfileStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    appContext: AppContextStore = appContext;

    id: number;
    username: string;
    bio: string;

    publishedRecipes?: RecipeListStore;
    unpublishedRecipes?: RecipeListStore;
    creations?: RecipeListStore;

    totalUpvotes?: number;
    totalRuns?: number;
    totalViews?: number;

    credits?: number;
    referralCredits?: number;

    bonusCreditsDialogStore: BonusCreditsDialogStore = new BonusCreditsDialogStore();

    get totalRecipes() {
        return (this.publishedRecipes?.recipes.length ?? 0) +
            (this.unpublishedRecipes?.recipes.length ?? 0);
    }

    get totalCreations() {
        return (this.creations?.recipes.length ?? 0);
    }

    get totalCreationsFormatted(): string {
        const creations = this.totalCreations
        if (creations === 50) {
            return "50+";
        } else {
            return creations.toString();
        }
    }

    newUsername: string = "";

    setNewUsername(value: string) {
        this.newUsername = value;
    }

    async changeUserName(): Promise<void> {
        await apiClient.patch(`/api/v1/users/${this.id}`,
            {
                username: this.newUsername
            } as UpdateUserSchema);

        this.hideChangeUsernameModal();
        this.username = this.newUsername;

        window.location.href = `/users/${this.username}`;
    }

    isChangeUsernameModalVisible: boolean = false;

    showChangeUsernameModal() {
        this.isChangeUsernameModalVisible = true;
        this.newUsername = this.username;
    }

    hideChangeUsernameModal() {
        this.isChangeUsernameModalVisible = false;
    }

    newBio: string = "";
    isChangeBioModalVisible: boolean = false;

    showChangeBioModal() {
        this.isChangeBioModalVisible = true;
        this.newBio = this.bio;
    }

    hideChangeBioModal() {
        this.isChangeBioModalVisible = false;
    }

    setNewBio(value: string) {
        this.newBio = value;
    }

    async changeBio(): Promise<void> {
        await apiClient.patch(`/api/v1/users/${this.id}`,
            {
                bio: this.newBio
            } as UpdateUserSchema);

        this.hideChangeBioModal();
        this.bio = this.newBio;
    }


    get isMyProfile() {
        return this.appContext.currentUser?.id === this.id;
    }

    loadFromDto(dto: UserProfileSchema) {
        this.id = dto.user.id;
        this.username = dto.user.username;
        this.bio = dto.user.bio;

        this.totalUpvotes = dto.totalUpvotes;
        this.totalRuns = dto.totalRuns;
        this.totalViews = dto.totalViews;
        this.credits = dto.user.credits;
        this.referralCredits = dto.user.referralCredits;

        if (dto.publishedRecipes) {
            this.publishedRecipes = RecipeListStore.fromDto(dto.publishedRecipes);
        }
        if (dto.unpublishedRecipes) {
            this.unpublishedRecipes = RecipeListStore.fromDto(dto.unpublishedRecipes);
        }
        if (dto.creations) {
            this.creations = RecipeListStore.fromDto(dto.creations);
        }
    }
}
