import * as React from "react";
import {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";

import logo from "../../youml/static/images/logos/full-logo-dark-bg@0.5x.png";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {SignInModal} from "./SignInModal";
import {appContext} from "../AppContext";

export const Page = observer((props: PropsWithChildren<{ hideNavBar?: boolean, frontNavBar?: boolean }>) => {
    return (<div className="app-page-wrapper page-background page-background-1">
        {!props.hideNavBar &&
            <div className="container">
                <div className="row">
                    {props.frontNavBar && <div className="col-12 col-md-2 me-2 d-none d-lg-block">
                        &nbsp;
                    </div>}

                    <div className="col-12 col-md-9">
                        <Navbar className="page-navigation" expand="md">
                            <Navbar.Brand href="/">
                                <img className="app-logo" src={logo} alt="YouML"/>
                            </Navbar.Brand>
                            <Navbar.Collapse className="justify-content-end">
                                <Nav className="me-auto">
                                    <Nav.Link href="/recipes">Recipes</Nav.Link>

                                    {appContext.currentUser?.username && <>
                                        <Nav.Link href="/recipes/create">Create recipe</Nav.Link>
                                        <Nav.Link href={`/users/${appContext.currentUser.username}`}>My
                                            Profile</Nav.Link>
                                    </>}

                                    <NavDropdown title="Features" id="nav-features">
                                        <NavDropdown.Item href="/comfy-cloud-editor">
                                            Free Cloud ComfyUI
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="https://get.youml.com/comfy">
                                            ComfyUI API
                                        </NavDropdown.Item>
                                    </NavDropdown>

                                    {!appContext.currentUser?.username && <>
                                        <Nav.Link href="/accounts/login/">Sign in</Nav.Link>
                                    </>}

                                    <Nav.Link href="https://discord.gg/8axrU53uM8" title="Join Discord" rel="nofollow">
                                        <i className="fab fa-discord"></i>
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Navbar.Toggle/>
                        </Navbar>
                    </div>
                </div>
            </div>
        }
        <div className="page-content">
            {props.children}
        </div>

        <footer className="page-footer">
            <div className="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <ul className="nav col-12 col-md-3 justify-content-center justify-content-md-start">
                    <li className="nav-item">
                        <span className="nav-link px-0 text-body-secondary">
                        © {new Date().getFullYear()} <a href="/"
                                                        className="nav-link px-2 text-body-secondary">  YouML </a>
                        </span>
                    </li>
                </ul>
                <ul className="nav col-12 col-md-4 justify-content-center justify-content-md-end">
                    <li className="nav-item">
                        <a href="https://discord.gg/8axrU53uM8" className="nav-link px-2 text-body-secondary"
                           target="_blank">
                            Join Discord
                        </a>
                    </li>

                    <li className="nav-item">
                        <a href="https://discord.gg/XN5rmynDEk" target="_blank"
                           className="nav-link px-2 text-body-secondary">
                            Support
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/privacy" className="nav-link px-2 text-body-secondary">
                            Privacy
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
        <ToastContainer/>

        <SignInModal store={appContext.signInDialog}/>

    </div>);
})

