import {makeAutoObservable, runInAction} from "mobx";


interface LogRecord {
    id: number;
    message: string;
}

export class ComfyProjectLogViewerStore {
    constructor(init?: Partial<ComfyProjectLogViewerStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    private webSocket: WebSocket;
    private lastMessageId: number = 0;
    private lastMessageChange: Date = new Date();

    projectId: number;
    isListening: boolean = false;
    messages: LogRecord[] = [];

    maxMessages: number = 100;
    recentMessagesTimeoutMs: number = 10000;

    haveNewMessagesRecently: boolean = false;


    messagesRevision: number = 0;

    dialogVisible: boolean = false;

    showDialog() {
        this.dialogVisible = true;
    }

    hideDialog() {
        this.dialogVisible = false;
    }

    addMessages(messages: string[]) {
        const logRecords = messages.map((msg) => {
            return {
                id: this.lastMessageId++,
                message: msg
            }
        });

        this.messages.push(...logRecords);

        if (this.messages.length > this.maxMessages) {
            this.messages.splice(0, this.messages.length - this.maxMessages);
        }

        this.messagesRevision++;
        this.haveNewMessagesRecently = true;
        this.lastMessageChange = new Date();
        setTimeout(() => {
            this.handleRecentMessageChange()
        }, this.recentMessagesTimeoutMs);
    }

    private handleRecentMessageChange() {
        if (this.lastMessageChange.getTime() + this.recentMessagesTimeoutMs < new Date().getTime()) {
            runInAction(() => {
                this.haveNewMessagesRecently = false;
            });
        }
    }

    async startListening() {
        const thisStore = this;
        const protocol = window.location.protocol === "https:" ? "wss" : "ws";
        const websocketUrl = `${protocol}://${window.location.host}/comfy/projects/${this.projectId}/logs/ws`;

        this.webSocket = new WebSocket(websocketUrl);

        this.webSocket.onopen = () => {
            console.log("Logs websocket opened");
            runInAction(() => {
                thisStore.isListening = true;
            });
        }

        this.webSocket.onerror = (err) => {
            console.log("Logs websocket error: ", err);
            throw err;
        }

        this.webSocket.onclose = () => {
            console.log("Logs websocket closed");
            runInAction(() => {
                thisStore.isListening = false;
            });
        }

        this.webSocket.onmessage = (msg) => {
            // console.log("WS logs message: ", msg);

            if (!msg.data) {
                return;
            }

            const logMessages: string[] = JSON.parse(msg.data);

            runInAction(() => {

                thisStore.addMessages(logMessages);
            });
        }

    }

    async stopListening() {
        this.webSocket.close();
    }
}
