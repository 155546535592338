import {makeAutoObservable} from "mobx";
import {RecipeListStore} from "./RecipeListStore";
import {RecipeMode, RecipeStore} from "./RecipeStore";
import {UserInfo} from "../AppContext";
import {CreatorStatsSchema} from "../api";

export enum FrontPageVariant {
    Default = "default",
    ComfyUIShare = "comfy-ui-share",
}

export class CreatorStats {
    constructor(init?: Partial<CreatorStats>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    user: UserInfo;
    totalRuns: number;
    totalViews: number;

    static fromDto(dto: CreatorStatsSchema) {
        return new CreatorStats({
            user: UserInfo.fromDto(dto.user),
            totalRuns: dto.totalRuns,
            totalViews: dto.totalViews,
        });
    }
}

export class FrontPageStore {
    constructor(init?: Partial<FrontPageStore>) {
        makeAutoObservable(this);

        const urlParams = new URLSearchParams(window.location.search);
        const fromParam = urlParams.get("from");

        if (fromParam === "comfyui-share") {
            this.variant = FrontPageVariant.ComfyUIShare;
        } else {
            this.variant = FrontPageVariant.Default;
        }


        if (init) {
            Object.assign(this, init);
        }
    }

    loadFromDto(dto: any) {
        this.heroRecipe = RecipeStore.fromDto(dto.heroRecipe, RecipeMode.Default);
        this.trendingRecipes = RecipeListStore.fromDto(dto.trendingRecipes);
        this.trendingComfyRecipes = RecipeListStore.fromDto(dto.trendingComfyRecipes);
        this.newRecipes = RecipeListStore.fromDto(dto.newRecipes);
        this.topCreators = dto.topCreators.map(CreatorStats.fromDto);
    }

    variant: FrontPageVariant;

    trendingRecipes: RecipeListStore = new RecipeListStore();
    trendingComfyRecipes: RecipeListStore = new RecipeListStore();
    newRecipes: RecipeListStore = new RecipeListStore();

    heroRecipe: RecipeStore;

    topCreators: CreatorStats[];
}
