import {SubEvent} from "sub-events";
import {apiClient} from "../ApiClient";
import _ from "lodash";
import {logWebEvent} from "../utils/WebEventLogger";

export interface Recipe {
    id: number;
    name: string;
    workflow_ui_json_template: string;
    workflow_api_json_template: string;
    snapshot_json: string;
}

export class ComfyUiService {
    private comfyWindow: Window;
    private recipe: Recipe;


    constructor(comfyWindow: Window) {
        this.comfyWindow = comfyWindow;

        this.onComfyWindowLoaded.subscribe(() => {
            this.waitForComfyApp((app) => {
                this.onComfyAppLoaded.emit(app);
            });
        });

        comfyWindow.onload = () => {
            if (comfyWindow.document.readyState === "complete" || comfyWindow.document.readyState === "interactive") {
                this.initializeWindow();
            } else {
                comfyWindow.document.addEventListener('DOMContentLoaded', () => {
                    this.initializeWindow();
                });
            }
        }

        this.setupLoadingWatchdog();
    }


    private setupLoadingWatchdog() {
        // If the comfy ui does not start within 100 seconds,
        // the requests will time out on Cloudflare and it will never load
        // So we are reloading the page every X seconds until the app is loaded

        console.log("Waiting for Comfy app to load ...");
        const maxAttempts = 10;
        const reloadIntervalMs = 90 * 1000;

        const reloadParamName = "_r";

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let reloadAttempt = parseInt(params.get(reloadParamName) || "0");


        setTimeout(async () => {
            if (this.getComfyApp()) {
                console.log("Comfy app available, stopping watchdog.");
                logWebEvent("web.comfy.loading.succeeded", "Loaded Comfy editor",
                    {attempt: reloadAttempt.toString()}).then();
            } else {
                if (reloadAttempt < maxAttempts) {
                    reloadAttempt++;
                    await logWebEvent("web.comfy.loading", "Still loading Comfy editor",
                        {attempt: reloadAttempt.toString()});

                    params.set(reloadParamName, reloadAttempt.toString());
                    url.search = params.toString();
                    window.location.href = url.toString()
                } else {
                    console.error("Failed to load Comfy app after " + maxAttempts + " attempts.");
                    logWebEvent("web.comfy.loading.failed", "Failed to load Comfy editor",
                        {attempt: reloadAttempt.toString()}).then()
                }
            }
        }, reloadIntervalMs);

    }

    getComfyApp(): any {
        const app = (this.comfyWindow as any).app;
        return app;
    }

    /// Returns URL of the Comfy service app without trailing slash
    getComfyServiceUrlApp(): string {
        return _.trimEnd(this.comfyWindow.window.location.href, "/");
    }

    loadRecipe(recipe: Recipe) {
        this.recipe = recipe;

        this.waitForElement("#comfy-load-button", async (element) => {
            const app = this.getComfyApp();

            console.log("Loading recipe ...");

            const uiWorkflow = JSON.parse(recipe.workflow_ui_json_template);
            app.loadGraphData(uiWorkflow);

            console.log("Recipe loaded")

            this.onRecipeLoaded.emit(recipe);

            if (!recipe.workflow_api_json_template) {
                console.log("Saving recipe for the first time");
                await this.saveRecipe()
            }

            this.configureAutoSave();
        });
    }

    async saveRecipe() {
        console.log("Saving recipe ...");
        if (!this.recipe) {
            throw new Error("No recipe loaded");
        }
        const app = this.getComfyApp();
        const result = await app.graphToPrompt();

        const oldApiTemplate = this.recipe.workflow_api_json_template;
        const oldUiTemplate = this.recipe.workflow_ui_json_template;

        try {
            this.recipe.workflow_api_json_template = JSON.stringify(result.output);
            this.recipe.workflow_ui_json_template = JSON.stringify(result.workflow);

            try {
                const requestUrl = this.getComfyServiceUrlApp() + "/snapshot/get_current";
                const snapshot = await fetch(requestUrl);
                this.recipe.snapshot_json = await snapshot.text();
            } catch (e) {
                console.error("Failed to get snapshot", e)
            }

            await apiClient.put(`/api/comfy/v1/recipes/${this.recipe.id}`, this.recipe);
            console.log("Recipe saved");
        } catch (e) {
            this.recipe.workflow_api_json_template = oldApiTemplate;
            this.recipe.workflow_ui_json_template = oldUiTemplate;
            throw e;
        }
    }

    async autoSave() {
        const app = this.getComfyApp();
        const result = await app.graphToPrompt();

        const workflowApiJson = JSON.stringify(result.output);
        const workflowUiJson = JSON.stringify(result.workflow);

        if (workflowApiJson !== this.recipe.workflow_api_json_template ||
            workflowUiJson !== this.recipe.workflow_ui_json_template) {
            console.log("Auto saving recipe ...");
            await this.saveRecipe();
        }
    }

    configureAutoSave() {
        setInterval(async () => {
            await this.autoSave();
        }, 5000);
    }

    refresh() {
        this.waitForElement("#comfy-refresh-button", (element) => {
            console.log("Refreshing ...");
            element.click();
        });
    }

    onComfyWindowLoaded: SubEvent<Window> = new SubEvent();
    onComfyAppLoaded: SubEvent<object> = new SubEvent();
    onRecipeLoaded: SubEvent<Recipe> = new SubEvent();

    onNetworkActivityStarted: SubEvent = new SubEvent();
    onNetworkActivityStopped: SubEvent = new SubEvent();

    initializeWindow() {
        console.log("Initializing comfy window...");
        this.onComfyWindowLoaded.emit(this.comfyWindow);

        this.addNetworkActivityProgressMonitor();

        this.waitForElement("#shareButton", (element) => {
            element.style.display = "none";
        });

        this.waitForElement("#comfy-save-button", (element) => {
            element.innerText = "Export to file";
            const saveRecipeButton = document.createElement("button");
            saveRecipeButton.innerText = "Save recipe";
            saveRecipeButton.id = "yml-save-recipe-button";
            saveRecipeButton.onclick = async () => {
                await this.saveRecipe();
            }
            element.insertAdjacentElement("beforebegin", saveRecipeButton);

            const separator = document.createElement("hr");
            separator.style.margin = "10px 0px"
            separator.style.width = "100%"
            element.insertAdjacentElement("beforebegin", separator);
        });

        this.waitForElement("#comfy-load-button", (element) => {
            element.innerText = "Import from file";

            const oldOnClick = element.onclick;
            element.onclick = (e) => {
                if (confirm("We recommend that you create a new recipe instead of loading a workflow into a current one. " +
                    "When workflow is provided during creation of a new recipe, custom nodes and models will be automatically downloaded. " +
                    "Are you sure you want to load workflow into current recipe?")) {
                    oldOnClick.bind(element, e)();
                }
            }
        });

        this.waitForElement("#comfyworkflows-button", (element) => {
            element.style["display"] = "none";
        });

        this.waitForElement("#workflowgallery-button", (element) => {
            element.style["display"] = "none";
        });

        console.log("Comfy window initialization prepared");
    }

    private activeFetchRequestCount = 0;

    addNetworkActivityProgressMonitor() {
        const oldFetch = this.comfyWindow.fetch;
        this.comfyWindow.fetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
            const overrideResponse = await this.networkCallOverrideHandler(input, init);
            if (overrideResponse) {
                return overrideResponse;
            }

            this.activeFetchRequestCount++;
            if (this.activeFetchRequestCount === 1) {
                try {
                    this.onNetworkActivityStarted.emit({});
                } catch (e) {
                    console.error("Error while emitting onNetworkActivityStarted", e);
                }
            }

            try {
                return await oldFetch(input, init);
            } finally {
                this.activeFetchRequestCount--;
                if (this.activeFetchRequestCount === 0) {
                    try {
                        this.onNetworkActivityStopped.emit({});
                    } catch (e) {
                        console.error("Error while emitting onNetworkActivityStopped", e);
                    }
                }
            }
        }
    }

    comfyUiRestartHandler: () => void;

    async networkCallOverrideHandler(input: RequestInfo | URL, init?: RequestInit): Promise<Response | null> {
        if (input && typeof input == "string" && input.endsWith("/manager/reboot") && this.comfyUiRestartHandler) {
            this.comfyUiRestartHandler();
            return new Response("{}", {status: 200});
        }

        return null;
    }

    waitForComfyApp(callback: (app: any) => void) {
        if (this.getComfyApp()) {
            callback(this.getComfyApp());
            return;
        }

        setTimeout(() => {
            this.waitForComfyApp(callback);
        }, 1000)
    }

    waitForElement(selector: string, callback: (element: HTMLElement) => void) {
        const doc = this.comfyWindow.document;
        let element = doc.querySelector(selector);
        if (element) {
            callback(<HTMLElement>element);
            return;
        }

        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {

                element = doc.querySelector(selector);
                if (element) {
                    observer.disconnect();
                    callback(<HTMLElement>element);
                    return;
                }
            });
        });

        observer.observe(doc.documentElement, {childList: true, subtree: true});
    }

}
