import * as React from "react";
import {observer} from "mobx-react";
import {RecipeListStore} from "./RecipeListStore";
import {RecipeCard} from "./RecipeCard";


export const RecipeList = observer((props: {
    store: RecipeListStore,
    justifyRecipes?: boolean,
    minimalCards?: boolean
}) => {
    const store = props.store;
    return (<div className={"recipe-list " + (props.justifyRecipes ? "justify-recipes" : "")}>
        <div className="d-flex flex-wrap recipe-list-container">
            {store.recipes.length == 0 &&
                <div className="recipe-list-empty-message">
                    <p>
                        No recipes found
                    </p>
                </div>}

            {store.recipes.length > 0 && store.recipes.map(recipe =>
                <div className="recipe-card-container">
                    <RecipeCard store={recipe} minimal={props.minimalCards}/>
                </div>)}

        </div>

    </div>);
})

