import {instanceToPlain, plainToInstance} from "class-transformer";
import {ClassConstructor} from "class-transformer/types/interfaces";

export function getApiToken() {
    return (window as any)?.apiToken;
}

export function setApiToken(token: string) {
    (window as any).apiToken = token;
}

export class ApiClient {
    async get<TOutput>(url: string, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        return await this.send(url, null, null, outputClass);
    }

    async post<TOutput>(url: string, bodyObject?: object, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        return await this.send(url, bodyObject, {
            method: "POST"
        }, outputClass);
    }

    async patch<TOutput>(url: string, bodyObject?: object, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        return await this.send(url, bodyObject, {
            method: "PATCH"
        }, outputClass);
    }

    async put<TOutput>(url: string, bodyObject?: object, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        return await this.send(url, bodyObject, {
            method: "PUT"
        }, outputClass);
    }

    async delete<TOutput>(url: string, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        return await this.send(url, null, {
            method: "DELETE"
        }, outputClass);
    }

    async send<TOutput>(url: string, bodyObject?: object, init?: RequestInit, outputClass?: ClassConstructor<TOutput>): Promise<TOutput> {
        const fetchInit = init || {};
        fetchInit.credentials = "include";
        fetchInit.headers = {};

        const apiToken = getApiToken();
        if (apiToken) {
            fetchInit.headers["Authorization"] = "Bearer " + apiToken;
        }

        const csrfToken = (window as any)?.csrfToken;
        if (csrfToken) {
            fetchInit.headers["X-CSRFToken"] = csrfToken;
        }

        if (bodyObject) {
            fetchInit.body = JSON.stringify(instanceToPlain(bodyObject));
            fetchInit.headers["Content-Type"] = "application/json";
        }
        const result = await fetch(url, fetchInit);

        if (!result.ok) {
            throw new Error("Request failed. Status code: " + result.status + ". " + await result.text());
        }

        const responseText = await result.text();

        if (outputClass) {
            return plainToInstance(outputClass, JSON.parse(responseText));
        }

        if (responseText) {
            return JSON.parse(responseText);
        }

        return undefined;
    }
}

export const apiClient = new ApiClient();
