import * as React from "react";
import {observer} from "mobx-react";
import {RecipeParameter, RecipeStore, ResultDisplayStyle, ValueKind} from "./RecipeStore";
import {VotingComponent} from "./VotingComponent";
import {Button, ButtonGroup} from "react-bootstrap";
import {ReactCompareSlider, ReactCompareSliderImage} from "../../node_modules/react-compare-slider";

import logo from "../../youml/static/images/logos/full-logo-dark-bg@0.5x.png";
import {Preloader} from "./Preloader";
import {PreloaderStore} from "./PreloaderStore";
import {RecipeSharingDialog} from "./RecipeSharingDialog";
import {CommentList} from "./CommentList";


export const RecipeResultContent = observer((props: { store: RecipeParameter }) => {
    const store = props.store;
    return (
        <div className="recipe-result-content-viewer">
            {store.kind === ValueKind.Image && !store.isVideo &&
                <img className="rounded-3 image-loading-background recipe-parameter-image" src={store.value}
                     alt={store.displayName}/>}

            {store.isVideo &&
                <video loop autoPlay playsInline muted={true}
                       poster="/static/images/backgrounds/loading-background.png"
                       className="rounded-3 recipe-parameter-image">
                    <source src={store.value} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>}

            {store.kind === ValueKind.Text &&
                <div className="border border-1 rounded-3 p-3 recipe-parameter-text">
                    {store.value}
                </div>}
        </div>);
})

export const RecipeResultViewer = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (
        <div className="recipe-result-viewer">

            {store.isFailedRecipeResult && <RecipeErrorBlock store={store}/>}

            {(!store.canChangeResultDisplayStyle || store.resultDisplayStyle === ResultDisplayStyle.Result) &&
                store.visibleOutputs.map((output, index) => {
                    return <RecipeResultContent key={index} store={output}/>
                })}

            {(store.canChangeResultDisplayStyle && store.resultDisplayStyle === ResultDisplayStyle.SideBySide) &&
                <div className="container side-by-side-viewer">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-4 before-block mx-3 recipe-result-side">
                            <h5 className="m-0 mt-3 before-after-header">Before</h5>
                            {store.visibleInputs.map((output, index) => {
                                return <RecipeResultContent key={index} store={output}/>
                            })}
                        </div>
                        <div className="col-12 col-lg-4 after-block mx-3 recipe-result-side">
                            <h5 className="m-0 mt-3 before-after-header">After</h5>
                            {store.visibleOutputs.map((output, index) => {
                                return <RecipeResultContent key={index} store={output}/>
                            })}

                        </div>
                    </div>
                </div>
            }

            {(store.canChangeResultDisplayStyle && store.resultDisplayStyle === ResultDisplayStyle.Split) &&
                <div className="slider-viewer">
                    <ReactCompareSlider
                        className="rounded-3 compare-slider"
                        itemOne={<ReactCompareSliderImage
                            src={store.visibleInputsPreferImage[0].value}
                            alt={store.visibleInputsPreferImage[0].displayName}
                        />}

                        itemTwo={<ReactCompareSliderImage
                            src={store.visibleOutputs[0].value}
                            alt={store.visibleOutputs[0].displayName}
                        />}
                    />
                </div>}
        </div>);
})


export const RecipeResultPublicToolbar = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (
        <div className="mt-4 recipe-result-toolbar">

            <div className="action-buttons">
                <VotingComponent store={store}/>

                <Button variant="secondary" className="px-3 action-button" onClick={() => store.showSharingDialog()}>
                    <i className="fas fa-share me-2"></i>Share
                </Button>

                {store.canChangeResultDisplayStyle &&
                    <ButtonGroup aria-label="Display style">
                        <Button
                            className="px-2 action-button"
                            variant={store.resultDisplayStyle === ResultDisplayStyle.Result ? "light" : "secondary"}
                            onClick={() => store.setResultDisplayStyle(ResultDisplayStyle.Result)}>
                            See Result
                        </Button>

                        {store.canShowSplitView &&
                            <Button
                                className="px-2 action-button"
                                variant={store.resultDisplayStyle === ResultDisplayStyle.Split ? "light" : "secondary"}
                                onClick={() => store.setResultDisplayStyle(ResultDisplayStyle.Split)}>
                                Split
                            </Button>
                        }

                        <Button
                            className="px-2 action-button"
                            variant={store.resultDisplayStyle === ResultDisplayStyle.SideBySide ? "light" : "secondary"}
                            onClick={() => store.setResultDisplayStyle(ResultDisplayStyle.SideBySide)}>
                            Before &amp; After
                        </Button>
                    </ButtonGroup>
                }

                <Button variant="secondary" className="px-4 action-button"
                        href={store.rerunRecipeUrl}>
                    <i className="far fa-sparkles me-2"></i>
                    {store.isOwner ? "Make another one" : "Make one for me!"}
                </Button>

                {store.canEdit &&
                    <Button variant="secondary" className="px-3 action-button" title="Delete"
                            onClick={() => store.delete()}
                    >
                        <i className="fas fa-trash"></i>
                    </Button>
                }

            </div>

            <div className="user-info">
                <a href={store.owner.profileUrl} className="nav-link">
                    <div className="me-2 user-avatar">
                        <i className="fad fa-user-circle"></i>
                    </div>

                    <div className="user-name">
                        {store.owner.username}
                    </div>
                </a>
            </div>
        </div>);
})


export const RecipeResultCreatorBlock = observer((props: {
    store: RecipeStore
}) => {
    const store = props.store;
    return (<div className="mt-3 creator-tools-block">
            {store.hasVisibleImageOutput &&
                <div className="row justify-content-center my-3">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="border border-1 rounded-3 py-3 my-md-2 px-3 creators-toolbar">
                            <div className="text-muted toolbar-title">
                                Creator tools<br/>
                                <span className="toolbar-subtitle">
                        visible only to you
                        </span>
                            </div>
                            <div className="action-buttons">

                                <Button variant="secondary" onClick={() => store.setAsTemplateThumbnail()}>
                                    <i className="far fa-sparkles me-2"></i>Use this image as thumbnail
                                </Button>

                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>
    );
})


export const RecipeErrorBlock = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="my-3 recipe-error-block">
            <div className="alert alert-warning recipe-failed-alert" role="alert">
                <div>
                    Oops! Looks like something went wrong. You can try <a href={store.rerunRecipeUrl}>running the recipe
                    again</a>.
                </div>
                {store.showFailedComfyRecipeMessage &&
                    <div className="mt-3">
                        You can <a href={store.templateUrl}>edit the recipe</a> in ComfyUI
                        to check if it works and then run it again.
                    </div>
                }
            </div>

        </div>
    );
})


export const RecipeResult = observer((props: {
    store: RecipeStore
}) => {
    const store = props.store;
    return (
        <div className="container recipe-result-v2">
            <div className="mb-3 mt-2 recipe-result-header">
                <div className="app-logo-container">
                    <a href="/">
                        <img className="app-logo" src={logo}/>
                    </a>
                </div>

                <div>
                    <a href={store.templateUrl} className="link-no-decoration">
                        <h1 className="recipe-name">{store.name}</h1>
                    </a>
                </div>

            </div>

            {!store.isRunning && <>
                <div className="row">
                    <div className="col align-middle">
                        <RecipeResultViewer store={store}/>
                        <div>
                            <RecipeResultPublicToolbar store={store}/>
                        </div>
                    </div>
                </div>

                {store.canEditTemplate &&
                    <RecipeResultCreatorBlock store={store}/>}

                {store.comments &&
                    <div className="row justify-content-center mt-3">
                        <div className="col-12 col-md-6">
                            <h4 className="title">Comments</h4>
                            <CommentList store={store.comments}/>
                        </div>
                    </div>
                }

                <div className="mt-5 mb-3 text-center">
                    <a href="/recipes" className="btn btn-secondary">
                        <i className="fas fa-fire me-2"></i>
                        Explore Trending Recipes
                    </a>
                </div>
            </>}

            {store.isRunning &&
                <div className="my-2 mt-5 pt-5">
                    <Preloader store={new PreloaderStore({message: "This can take a few minutes ..."})}
                               centerContainer={true}/>

                </div>}

            <RecipeSharingDialog store={store}/>


        </div>
    );
})

