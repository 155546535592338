import {apiClient} from "../ApiClient";

export async function logWebEvent(type: string, message: string, params: Record<string, string>) {
    await apiClient.post("/api/web-events", {
        type: type,
        message: message,
        params: params
    });

}
