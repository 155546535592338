import * as React from "react";
import {observer} from "mobx-react";
import {RecipeMode, RecipeParameter, RecipeStore, RecipeType, ValueKind} from "./RecipeStore";
import Markdown from "react-markdown";
import {Dashboard as DashboardComponent} from "@uppy/react";
import ScrollIntoView from "react-scroll-into-view";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import {VotingComponent} from "./VotingComponent";
import {Button, ButtonGroup, Dropdown, DropdownButton, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import {RecipeListStore} from "./RecipeListStore";
import {RecipeCard} from "./RecipeCard";
import {RecipeSharingDialog} from "./RecipeSharingDialog";
import {RecipeDetailsStore} from "./RecipeDetailsStore";
import {BonusCreditsDialog} from "./BonusCreditsDialog";
import {bonusCreditsDialogStore} from "./BonusCreditsDialogStore";
import {CommentList} from "./CommentList";

export const RecipeStarter = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (
        <div className="w-100 recipe-starter">
            <div className="recipe-parameters">
                {store.inputs.filter(i => !i.hidden).map((input, index) =>
                    <div key={index}
                         className="mb-3 d-flex flex-column align-items-center align-items-md-start recipe-parameter">
                        <label className="form-label">{input.displayName}</label>
                        {input.kind === ValueKind.Image &&
                            <div className="">
                                <DashboardComponent
                                    className="uppy-dashboard"
                                    id={"uppy-input-" + index}
                                    uppy={input.uppyImageUploader}
                                    proudlyDisplayPoweredByUppy={false}
                                    theme="dark"
                                    hideUploadButton={true}
                                    hideCancelButton={true}
                                    disableStatusBar={true}
                                    autoOpenFileEditor={true}
                                    showRemoveButtonAfterComplete={true}
                                    height="300px"
                                    width="300px"
                                />
                            </div>
                        }

                        {input.kind === ValueKind.Text &&
                            <textarea rows={2} className="form-control"
                                      value={input.value}
                                      onChange={(e) => input.setValue(e.target.value)}
                            />}

                        {input.description &&
                            <div className="form-text">
                                {input.description}
                            </div>}

                    </div>)}
            </div>
            <div className="mt-4 action-buttons">
                <button type="submit" className="btn btn-primary btn-lg border-0 btn-run-recipe w-100 w-md-auto"
                        disabled={!store.canCreateNow}
                        onClick={() => store.runRecipe()}>
                    Run Recipe <i className="far fa-play ms-2"></i>
                </button>
                {store.isStartingRecipe &&
                    <div>
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Creating ...</span>
                        </Spinner>
                    </div>
                }
            </div>
            {!store.haveEnoughCreditsToRun &&
                <div className="d-flex">
                    <div className="alert alert-warning my-3" role="alert">
                        <div>
                            You have run out of credits<br/>
                            <a href="#" className="btn btn-primary my-2" onClick={(e) => {
                                e.preventDefault();
                                bonusCreditsDialogStore.showDialog();
                            }}>
                                Get more credits for free
                            </a>

                        </div>
                    </div>
                </div>
            }
            {store.showLowCreditsWarning &&
                <div className="d-flex">
                    <div className="alert alert-info my-3" role="alert">
                        <div>
                            You have {store.recipeRunsRemaining} generations remaining<br/>
                            <a href="#" className="btn btn-primary my-2" onClick={(e) => {
                                e.preventDefault();
                                bonusCreditsDialogStore.showDialog();
                            }}>
                                How to get more for free?
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>);
})

export const RecipeParameterViewer = observer((props: { store: RecipeParameter }) => {
    const store = props.store;
    return (
        <div className="recipe-parameter-viewer">
            {store.kind === ValueKind.Image && !store.isVideo &&
                <img className="rounded-3 image-loading-background recipe-parameter-image" src={store.value}
                     alt={store.displayName}/>}

            {store.isVideo &&
                <video loop autoPlay playsInline muted={true}
                       poster="/static/images/backgrounds/loading-background.png"
                       className="rounded-3 recipe-parameter-image">
                    <source src={store.value} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>}

            {store.kind === ValueKind.Text &&
                <div className="border border-1 rounded-3 recipe-parameter-text">
                    {store.value}
                </div>}
        </div>);
})


export const RecipeToolbar = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (
        <div className="mt-4 recipe-toolbar">

            <div className="action-buttons">
                <div className="d-block d-md-none">
                    <ScrollIntoView selector="#runRecipeSection">
                        <button className="btn btn-primary">
                            Try it yourself
                        </button>
                    </ScrollIntoView>
                </div>

                <VotingComponent store={store}/>

                <Button variant="secondary" className="px-3 action-button" onClick={() => store.showSharingDialog()}>
                    <i className="fas fa-share me-2"></i>Share
                </Button>

                {store.canRemix &&
                    <a className="btn btn-secondary px-3 d-none d-md-block"
                       title="Create a copy of this recipe, and change it to make it your own"
                       onClick={() => store.showRemixInfoDialog()}>
                        <i className="fas fa-random recipe-action-button-icon me-2"></i>Remix
                    </a>
                }

                {store.downloadWorkflowUrl &&
                    <a className="btn btn-secondary d-none d-md-block px-3" title="Download workflow"
                       href={store.downloadWorkflowUrl} rel="nofollow">
                        <i className="fas fa-arrow-to-bottom recipe-action-button-icon"></i>
                    </a>
                }
                {store.mode == RecipeMode.Details &&
                    <a href={store.apiPageUrl} className="btn btn-secondary px-3 d-none d-md-block"
                       title="Run this recipe using API">
                        <i className="fas fa-code me-2"></i>API
                    </a>
                }
                {store.mode == RecipeMode.ApiDetails &&
                    <a href={store.url} className="btn btn-secondary px-3 d-none d-md-block">
                        <i className="far fa-hat-chef me-2"></i>Recipe Page
                    </a>
                }

                {store.canEdit &&
                    <ButtonGroup>
                        <Button variant="secondary" className="px-3" href={store.editPageUrl}>
                            <i className="fas fa-edit recipe-action-button-icon me-2"></i>Edit
                        </Button>

                        <DropdownButton as={ButtonGroup} title="" id="recipe-detail-toolbar-dropdown"
                                        variant="secondary">
                            <Dropdown.Item eventKey="delete" onClick={() => store.delete()}>Delete</Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                }

            </div>

            <div className="recipe-info-bar">
                <div className="user-info">
                    <a href={store.owner.profileUrl} className="nav-link">
                        <div className="me-2 user-avatar">
                            <i className="fad fa-user-circle"></i>
                        </div>

                        <div className="user-name">
                            {store.owner.username}
                        </div>
                    </a>
                </div>

                {store.remixedFrom &&
                    <div className="remix-info">
                        <a href={store.remixedFrom.url} className="nav-link">
                            <i className="fas fa-random me-2"></i>
                            remixed from the recipe by {store.remixedFrom.owner.username}
                        </a>
                    </div>
                }
            </div>
        </div>)
        ;
})


export const BeforeAfterComponent = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (
        <div className="before-after">
            {store.thumbnailRecipe &&
                <>
                    {store.thumbnailRecipe.inputsForBeforeAndAfter.length > 0 && <>
                        <div className="recipe-inputs-outputs-container recipe-inputs">
                            {store.thumbnailRecipe.inputsForBeforeAndAfter.slice(0, 4).map((input, index) =>
                                <React.Fragment key={index}>
                                    {index > 0 && <div className="recipe-input-output-divider">
                                        <i className="fas fa-plus"></i>
                                    </div>}
                                    <div className="recipe-input-output recipe-input">
                                        {input.kind === ValueKind.Image
                                            ? <a href={store.thumbnailRecipe.url}>
                                                <RecipeParameterViewer store={input}/>
                                            </a>
                                            : <RecipeParameterViewer store={input}/>
                                        }

                                    </div>
                                </React.Fragment>)}
                        </div>

                        <div className="recipe-transformation-divider">
                            <i className="fas fa-long-arrow-right"></i>
                        </div>
                    </>
                    }

                    <div className="recipe-inputs-outputs-container recipe-outputs">
                        {store.thumbnailRecipe.outputs.slice(0, 2).map((output, index) => {
                            return <div key={index} className="recipe-input-output recipe-output">
                                <a href={store.thumbnailRecipe.url}>
                                    <RecipeParameterViewer store={output}/>
                                </a>
                            </div>
                        })}
                    </div>
                </>
            }


            {
                !store.thumbnailRecipe && store.thumbnailUrl &&
                <div className="legacy-thumbnail-container">
                    <img className="rounded-3 legacy-thumbnail"
                         src={store.animatedThumbnailUrl || store.thumbnailUrl} alt={store.name}/>
                </div>
            }
            <div className="flex-filler">

            </div>

        </div>)
        ;
})


export const RecipeExpandableList = observer((props: { store: RecipeListStore, minimalCards?: boolean }) => {
    const store = props.store;
    return (
        <div className="recipe-expandable-list">
            <div className="d-flex flex-wrap recipe-list-container">
                {store.recipes.length > 0 && store.visibleRecipes.map(recipe =>
                    <div key={recipe.id} className="recipe-card-container">
                        <RecipeCard store={recipe} minimal={!!props.minimalCards}/>
                    </div>)}
            </div>
            {store.hasMoreItems &&
                <div className="expand-button-container">
                    <hr className="expand-separator"/>
                    <button className="btn btn-secondary mx-3 py-1 rounded-5 expand-button"
                            onClick={() => store.toggleExpanded()}>
                        Show {store.isExpanded ? "less" : "more"}
                    </button>
                </div>
            }
        </div>);
})


export const RemixInfoDialog = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="mt-5 recipe-sharing-dialog">
        <Modal show={store.remixInfoDialogVisible}
               onHide={() => store.hideRemixInfoDialog()}>
            <Modal.Header closeButton>
                <Modal.Title>Remix</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    You are about to create a new recipe based on this one.
                </p>

                {store.recipeType == RecipeType.Simple && <p>
                    You will be redirected to the editor, where you can change the AI prompts of the recipe.
                </p>}

                {store.recipeType == RecipeType.Comfy && <p>
                    We will create a new cloud ComfyUI project for you, where you can edit a copy of this workflow.
                    This can take several minutes.
                </p>}
            </Modal.Body>
            <Modal.Footer>
                {store.remixing
                    ? <>
                        <Button variant="primary" disabled={true}>
                            Remixing ...
                        </Button>
                    </>
                    : <>
                        <Button variant="primary" onClick={() => store.remix()}>
                            Remix
                        </Button>
                        <Button variant="secondary" onClick={() => store.hideRemixInfoDialog()}>
                            Close
                        </Button>
                    </>

                }

            </Modal.Footer>
        </Modal>

    </div>);

})


export const RecipeApiBlock = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="mw-100">
        {store.isComfyRecipe && <p>
            Run this ComfyUI recipe using API
        </p>}

        <p>
            <a className="btn btn-primary btn-sm me-2" href="/my-token" target="_blank">
                Get your API token
            </a>
        </p>

        <Tabs className="mb-3">
            <Tab eventKey="curl" title="HTTP (curl)">
                <pre className="pre-scrollable">export YOUML_API_TOKEN=XXXX</pre>
                <pre className="my-2 overflow-auto"><code>{store.apiBlockCurl}</code></pre>
            </Tab>
            <Tab eventKey="powershell" title="HTTP (PowerShell)">
                <pre className="pre-scrollable">$env:YOUML_API_TOKEN="XXXX"</pre>
                <pre className="my-2 overflow-auto"><code>{store.apiBlockPowershell}</code></pre>
            </Tab>
            <Tab eventKey="javascript" title="JavaScript">
                <pre className="my-2 overflow-auto"><code>{store.apiBlockJavascript}</code></pre>
            </Tab>
            <Tab eventKey="python" title="Python">
                <pre className="my-2 overflow-auto"><code>{store.apiBlockPython}</code></pre>
            </Tab>
        </Tabs>
        <p>
            For image inputs, you can use any publicly-accessible URL.
            If you use S3-compatible storage, you can use the signed URL.
            You will receive a JSON response with the result of the recipe. You can get images from "outputs"
            field.
        </p>

    </div>)
})


export const ComfyRecipeMetadata = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="mw-100 comfy-metadata">
        <div>This recipe uses the following models:</div>
        <div className="model-list">
            {store.comfyMetadata?.models.map((model, index) =>
                <span key={index} className="badge bg-secondary">
                     {model}
                 </span>
            )}
        </div>

        <div className="mt-3">ComfyUI Nodes:</div>
        <div className="node-list">
            {store.comfyMetadata?.customNodes.map((nodeName, index) =>
                <span key={index} className="badge bg-secondary">
                    {nodeName}
                </span>
            )}
        </div>

    </div>)
})

export const RecipeDetails = observer((props: { store: RecipeDetailsStore }) => {
    const store = props.store;
    return (
        <div className="container recipe-detail-v2">
            <div className="row gy-5 g-md-5">
                <div className="col-12 col-md-8 px-2 px-md-4">
                    <div className="recipe-header-panel">
                        <h1>
                            {store.recipe.mode === RecipeMode.ApiDetails && <span>API: </span>}
                            {store.recipe.name}
                        </h1>
                        {store.recipe.isDraft &&
                            <div>
                                <span className="badge bg-light">Draft</span>
                            </div>
                        }
                        <div className="flex-grow-1"></div>
                    </div>
                    <BeforeAfterComponent store={store.recipe}/>

                    {!store.recipe.thumbnailRecipe && store.recipe.isOwner && !store.recipe.isDraft &&
                        <div className="d-flex align-content-start mt-3">
                            <div className="alert d-flex align-items-center alert-info text-light">
                                <i className="fas fa-images lead me-3"></i>
                                <div>
                                    Use one of your creations as thumbnail to show "before &amp; after"
                                    transformation,<br/>
                                    and to list your recipe in the catalog.
                                </div>
                            </div>
                        </div>}

                    {store.recipe.isDraft && store.recipe.isOwner &&
                        <div className="d-flex flex-row justify-content-start align-content-start mt-3">
                            <div className="alert d-flex align-items-center alert-info text-light">
                                <i className="far fa-info-circle lead me-3"></i>
                                <div>
                                    This recipe is a draft. Click on Edit to publish it.
                                </div>
                            </div>
                        </div>
                    }

                    <RecipeToolbar store={store.recipe}/>

                    <div className="mt-2 description">
                        <Markdown>{store.recipe.description}</Markdown>
                    </div>

                    {store.recipe.mode === RecipeMode.ApiDetails &&
                        <>
                            <div className="mt-4 recipe-detail-section">
                                <h2 className="title">Run Recipe Using API</h2>
                                <RecipeApiBlock store={store.recipe}/>
                            </div>
                            {store.recipe.comfyMetadata &&
                                <div className="mt-2">
                                    <ComfyRecipeMetadata store={store.recipe}/>
                                </div>
                            }
                        </>
                    }

                    <div className="mt-4 recipe-detail-section pe-1 pe-lg-5 me-lg-3">
                        <h4 className="title">Comments</h4>
                        <CommentList store={store.recipe.comments}/>
                    </div>

                    {store.myCreations?.hasItems &&
                        <div className="mt-4 recipe-detail-section">
                            <h4 className="title">My Creations</h4>
                            <RecipeExpandableList store={store.myCreations} minimalCards={true}/>
                        </div>
                    }

                    {store.communityCreations?.hasItems &&
                        <div className="mt-4 recipe-detail-section">
                            <h4 className="title">Community Creations</h4>
                            <RecipeExpandableList store={store.communityCreations} minimalCards={true}/>
                        </div>
                    }

                    {store.featured?.hasItems &&
                        <div className="mt-4 recipe-detail-section">
                            <h4 className="title">Featured Recipes</h4>
                            <RecipeExpandableList store={store.featured} minimalCards={false}/>
                        </div>
                    }

                    <div className="mt-5 mb-3 text-center text-md-start">
                        <a href="/recipes" className="btn btn-secondary">
                            <i className="fas fa-fire me-2"></i>
                            See More Trending Recipes
                        </a>
                    </div>

                </div>
                <div className="col-md-4 recipe-detail-section">
                    <h2 id="runRecipeSection" className="mt-2 title">Try yourself</h2>
                    <RecipeStarter store={store.recipe}/>
                </div>
            </div>

            <RecipeSharingDialog store={store.recipe}/>
            <RemixInfoDialog store={store.recipe}/>
            <BonusCreditsDialog store={bonusCreditsDialogStore}/>
        </div>);
})

