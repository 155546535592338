/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RecipeState {
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    RETRYING = 'RETRYING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}
