import Cookies from "js-cookie";
import {logWebEvent} from "./utils/WebEventLogger";


const discordCookieName = "discordJoinDate";

export function offerToJoinDiscord(): boolean {
    const joinDate = Cookies.get(discordCookieName);

    return !joinDate;
}

export async function joinDiscord() {
    window.open("https://discord.gg/8axrU53uM8", "_blank");

    await logWebEvent("web.joinDiscord.click", "User clicked on join discord button",
        {
            location: window.location.pathname + window.location.search
        });

    Cookies.set("discordJoinDate", Date.now().toString(), {expires: 365});
}
