import {makeAutoObservable} from "mobx";
import {CommentMode, CommentStore} from "./CommentStore";
import {CommentListSchema, CommentObjectType} from "../api";

export class CommentListStore {

    constructor(objectType: CommentObjectType, objectId: number, init?: Partial<CommentListStore>) {
        makeAutoObservable(this);

        this.objectType = objectType;
        this.objectId = objectId;

        this.newComment = new CommentStore();
        this.newComment.objectType = objectType;
        this.newComment.objectId = objectId;
        this.newComment.mode = CommentMode.Create;

        this.newComment.onCommentCreated = (comment: CommentStore) => {
            this.comments.unshift(comment);
        }

        if (init) {
            Object.assign(this, init);
        }
    }

    objectType: string;
    objectId: number;
    newComment: CommentStore;

    comments: CommentStore[] = [];

    isExpanded: boolean = false;
    expandedCommentCount: number = 3;


    static fromDto(objectType: CommentObjectType, objectId: number, dto: CommentListSchema): CommentListStore {
        const store = new CommentListStore(objectType, objectId);
        store.loadFromDto(dto);
        return store;
    }

    loadFromDto(dto: CommentListSchema) {
        this.comments = dto.items.map(c => CommentStore.fromDto(c));
    }

    get visibleComments(): CommentStore[] {
        const filtered = this.comments.filter(c => !c.isDeleted);

        if (!this.isExpanded) {
            return filtered.slice(0, this.expandedCommentCount);
        }
        return filtered;
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    get hasMoreComments() {
        return this.comments.filter(c => !c.isDeleted).length > this.expandedCommentCount;
    }
}
