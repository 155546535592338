import {makeAutoObservable} from "mobx";
import {appContext, AppContextStore} from "../AppContext";
import {logWebEvent} from "../utils/WebEventLogger";

export class BonusCreditsDialogStore {
    constructor(init?: Partial<BonusCreditsDialogStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    isDialogVisible: boolean = false;

    appContext: AppContextStore = appContext;

    showDialog() {
        this.isDialogVisible = true;
        logWebEvent("web.creditsDialog.show", "Displayed credits dialog", {}).then();
    }

    hideDialog() {
        this.isDialogVisible = false;
    }

    get profilePage(): string {
        return this.appContext.currentUser?.profileUrl
    }

}


export const bonusCreditsDialogStore: BonusCreditsDialogStore = new BonusCreditsDialogStore();
