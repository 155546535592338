import * as React from "react";
import {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {ComfyProjectLogViewerStore} from "./ComfyProjectLogViewerStore";
import {Modal} from "react-bootstrap";
import {useOnScreen} from "../utils/ReactLayoutHooks";


export const ComfyProjectLogViewer = observer((props: { store: ComfyProjectLogViewerStore }) => {
    const store = props.store;
    const messagesEndRef = useRef(null)

    const preMessagesEndRef = useRef(null)
    const preMessageEndOnScreen = useOnScreen(preMessagesEndRef);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "instant"})
    }

    useEffect(() => {
        if (preMessageEndOnScreen) {
            scrollToBottom();
        }
    }, [store.messagesRevision]);

    return (<div className="comfy-project-log-viewer">
        <Modal show={store.dialogVisible} onHide={() => store.hideDialog()} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>ComfyUI Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="message-list h-100 w-100 mh-100 mw-100">
                    <pre className="overflow-scroll h-100 w-100 text-wrap">
                        {store.messages.map(log => <div key={log.id}>{log.message}</div>)}
                        <span className="typing-dots ms-1">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <div ref={preMessagesEndRef}/>
                        <div ref={messagesEndRef}/>
                    </pre>
                </div>
                <div>

                </div>
            </Modal.Body>
        </Modal>

    </div>);
})


export const ComfyProjectLogViewerButton = observer((props: { store: ComfyProjectLogViewerStore }) => {
    const store = props.store;
    return (<>
        <a className="nav-link" href="#logs"
           onClick={(e) => {
               e.preventDefault();
               store.showDialog();
           }}>
            Logs
            <span className={"ms-1 " + (store.haveNewMessagesRecently ? "slow-blinking " : " ")}>
                <i className="fas fa-bars"></i>
            </span>
        </a>
        <ComfyProjectLogViewer store={store}/>
    </>);
})
