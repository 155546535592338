import {makeAutoObservable, runInAction} from "mobx";
import {CurrentUserInfo} from "../AppContext";

export class CurrentUserContext {
    user: CurrentUserInfo;
    apiToken: string;
}

const signInChannelName = "signinCompleteChannel";


export function reportSignInResult(result: CurrentUserContext) {
    const signInChannel = new BroadcastChannel(signInChannelName);
    signInChannel.postMessage(result);
}

export class SignInModalStore {
    constructor(init?: Partial<SignInModalStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    isVisible: boolean;

    show() {
        this.isVisible = true;
    }

    hide() {
        this.isVisible = false;
        if (this.onHide) {
            this.onHide();
        }
    }

    private onHide: () => void;

    async startInteractiveSignIn(): Promise<CurrentUserContext | null> {
        this.show();

        const promise = new Promise<CurrentUserContext | null>((resolve, reject) => {
            this.onHide = () => {
                signInChannel.close();
                resolve(null);
            }

            const signInChannel = new BroadcastChannel("signinCompleteChannel");
            signInChannel.onmessage = (event) => {
                const result = event.data as CurrentUserContext;
                signInChannel.close();

                this.onHide = null;
                runInAction(() => {
                    this.hide();
                });

                resolve(result);
            }
        });

        return promise;
    }

    openSignInWindow() {
        window.open("/accounts/google/login/?process=login&next=/account/signin-complete", "_blank");
    }
}
