import * as React from "react";
import {observer} from "mobx-react";
import {RecipeStore, ValueKind} from "./RecipeStore";
import {SimpleRecipeKind} from "../api";
import {RecipeStarter} from "./RecipeDetails";
import {BonusCreditsDialog} from "./BonusCreditsDialog";
import {bonusCreditsDialogStore} from "./BonusCreditsDialogStore";


export const AdvancedSettingsWithButtons = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div>
        <p className="my-2">
            <a className="btn btn-link px-0" href="#" role="button" aria-expanded="false"
               onClick={(e) => {
                   e.preventDefault();
                   store.toggleAdvancedSettings()
               }}
            >
                {store.showAdvancedSettings ? "Hide" : "Show"} more settings
            </a>
        </p>
        {store.showAdvancedSettings &&
            <div className="my-3">
                <h4>Advanced settings</h4>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description (markdown)</label>
                    <textarea id="description" name="description" className="form-control"
                              onChange={(e) => store.setDescription(e.target.value)}
                              rows={5}>{store.description}</textarea>
                </div>

                <div className="form-check mb-3">
                    <input id="can_remix" name="can_remix" className="form-check-input" type="checkbox"
                           checked={store.canRemix} onClick={() => store.setCanRemix(!store.canRemix)}
                    />
                    <label htmlFor="can_remix" className="form-check-label">
                        Allow other users to clone and remix this recipe
                    </label>
                </div>

                <div className="mb-3">
                    <h4>Input</h4>

                    {store.inputs.map((input, i) =>
                        <div key={i}>
                            <div className="mb-3">
                                <label className="form-label">Display name</label>
                                <input className="form-control" type="text"
                                       value={input.displayName}
                                       onChange={e => input.setDisplayName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <input className="form-control" type="text"
                                       value={input.description}
                                       onChange={e => input.setDescription(e.target.value)}
                                />
                            </div>

                            {input.kind === ValueKind.Text &&
                                <div className="mb-3">
                                    <label className="form-label">Default value</label>
                                    <input className="form-control" type="text"
                                           value={input.value}
                                           onChange={e => input.setValue(e.target.value)}
                                    />
                                </div>
                            }
                        </div>
                    )}

                </div>
            </div>
        }

        <div className="action-buttons">
            {store.isDraft &&
                <button className="btn btn-primary my-2"
                        onClick={() => store.publishAndGoToDetails()}>
                    Publish
                </button>}

            <button className="btn btn-secondary my-2"
                    onClick={() => store.saveChangesAndGoToDetails()}>
                Save {store.isDraft ? " Draft" : ""}
            </button>
        </div>
    </div>)
});

export const SimpleRecipeEditor = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="container simple-recipe-editor">
        <div className="row">
            <div className="col-12 col-lg-8">
                <div>
                    <h5>Edit Recipe</h5>
                </div>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input id="name" name="name" className="form-control" type="text" value={store.name}
                           onChange={e => store.setName(e.target.value)}/>
                </div>
                <div className="mb-3">
                    {store.simpleRecipeSettings.kind === SimpleRecipeKind.IMAGE_TO_TEXT_TO_IMAGE &&
                        <div className="mb-3">
                            <label htmlFor="pre_processing_prompt_template" className="form-label">
                                AI prompt to convert the image to text description
                                <i className="far fa-image ms-1"></i> <i className="fas fa-arrow-right"></i> <i
                                className="fas fa-text"></i>
                            </label>
                            <textarea id="pre_processing_prompt_template" name="pre_processing_prompt_template"
                                      className="form-control"
                                      onChange={(e) => store.simpleRecipeSettings.setPreProcessingPromptTemplate(e.target.value)}
                                      rows={5}>{store.simpleRecipeSettings.preProcessingPromptTemplate}</textarea>
                            <div className="form-text large-form-text">
                                For example: "Describe the image in details. Up to 30 words."
                            </div>
                        </div>
                    }

                    <div className="mb-3">
                        <label htmlFor="prompt_template" className="form-label">
                            {store.simpleRecipeSettings.kind === SimpleRecipeKind.TEXT_TO_IMAGE &&
                                <span>
                                    AI prompt to draw an image
                                    <i className="fas fa-text ms-1"></i> <i className="fas fa-arrow-right"></i> <i
                                    className="far fa-image"></i>
                                </span>}

                            {store.simpleRecipeSettings.kind === SimpleRecipeKind.IMAGE_TO_TEXT_TO_IMAGE &&
                                <span>
                                    AI prompt to draw a new image from text description
                                    <i className="fas fa-text ms-1"></i> <i className="fas fa-arrow-right"></i> <i
                                    className="far fa-image"></i>
                                </span>}
                        </label>
                        <textarea id="prompt_template" name="prompt_template" className="form-control"
                                  onChange={(e) => store.simpleRecipeSettings.setPromptTemplate(e.target.value)}
                                  rows={5}>{store.simpleRecipeSettings.promptTemplate}</textarea>


                        {store.simpleRecipeSettings.kind === SimpleRecipeKind.TEXT_TO_IMAGE &&
                            <div className="form-text large-form-text">
                                You can use {"{{input}}"} to insert the value of an input
                                into your prompt.<br/>
                                For example: "Draw a watercolor picture of {"{{input}}"}"
                            </div>}

                        {store.simpleRecipeSettings.kind === SimpleRecipeKind.IMAGE_TO_TEXT_TO_IMAGE &&
                            <div className="form-text large-form-text">
                                You can use {"{{image_description}}"} to insert the output of the
                                image-to-text prompt.<br/>
                                For example:
                                "Draw a watercolor from description: {"{{image_description}}"}"
                            </div>}
                    </div>
                </div>
                <div className="d-none d-lg-block">
                    <AdvancedSettingsWithButtons store={store}/>
                </div>
            </div>

            <div className="col-12 col-lg-4">
                <div className="recipe-detail-section text-center text-md-start">
                    <h5>Try Your Changes</h5>
                    <RecipeStarter store={store}/>
                </div>

                <div>
                    {store.generatingPreview &&
                        <div
                            className="my-3 d-flex flex-row align-items-center gap-2 justify-content-center justify-content-md-start">
                            <div className="spinner-border text-primary" role="status">
                            </div>
                            <div>
                                Generating preview ...
                            </div>
                        </div>}

                    {!store.generatingPreview && store.editorPreviewResult &&
                        <div className="my-4">
                            <h5>Result Preview</h5>
                            <div className="recipe-preview">
                                <img className="rounded-3"
                                     src={store.editorPreviewResult.animatedThumbnailUrl ?? store.editorPreviewResult.thumbnailUrl}
                                     alt="Preview"/>
                            </div>

                        </div>}


                </div>
            </div>

            <div className="col-12 d-lg-none">
                <AdvancedSettingsWithButtons store={store}/>
            </div>

        </div>

        <BonusCreditsDialog store={bonusCreditsDialogStore}/>
    </div>);
})

