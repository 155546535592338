import {makeAutoObservable} from "mobx";
import {joinDiscord, offerToJoinDiscord} from "../DiscordJoinHandler";

export class PreloaderStore {
    constructor(init?: Partial<PreloaderStore>) {
        makeAutoObservable(this);

        this.suggestToJoinDiscord = offerToJoinDiscord();
        if (init) {
            Object.assign(this, init);
        }
    }

    message: string;

    suggestToJoinDiscord: boolean;


    async openJoinDiscordWindow() {
        await joinDiscord();
    }

}
