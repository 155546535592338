import * as React from "react";
import {observer} from "mobx-react";
import {SignInModalStore} from "./SignInModalStore";
import {Modal} from "react-bootstrap";


export const SignInModal = observer((props: { store: SignInModalStore }) => {
    const store = props.store;
    return (<Modal show={store.isVisible}
                   onHide={() => store.hide()}
                   className="signin-modal"
                   size="sm">
        <div className="socialaccount_ballot">
            <Modal.Header>
                <Modal.Title>
                    Sign In to Continue
                </Modal.Title>
            </Modal.Header>
            <p>Join now and get free credits daily.</p>
            <Modal.Body>
                <div className="my-4">
                    <ul className="socialaccount_providers m-0">
                        <li>
                            <a title="Google"
                               target="_blank"
                               className="socialaccount_provider google "
                               href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   store.openSignInWindow()
                               }}>
                                Sign in with Google
                            </a>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
        </div>
    </Modal>);
})

