import {apiClient} from "../ApiClient";

export class UploadService {
    async downloadToSharedFolder(recipeId: number, url: string, targetFolder: string): Promise<void> {
        try {
            await apiClient.post(`/api/comfy/v1/recipes/${recipeId}/download-to-shared`, {
                "url": url,
                "targetFolder": targetFolder
            });
        } catch (e) {
            console.error(e);
            alert("Upload failed: " + e);
            throw e;
        }
    }
}
