import {toast} from "react-toastify";

let lastErrorShown = 0;

function onUnhandledError(message) {
    console.error("Unhandled exception: " + message);

    if (Date.now() - lastErrorShown > 1000) {
        lastErrorShown = Date.now();
        toast(message, {
            type: "error",
        });
    }
}

window.onerror = function myErrorHandler(errorMsg, url, lineNumber,
                                         column, errorObj) {
    console.error(errorObj);
    onUnhandledError(errorMsg);
};

window.addEventListener("unhandledrejection", function(event: any) {
    console.error("Unhandled rejection (promise: ", event.promise, ", reason: ", event.reason, ").");

    if (event.reason) {
        let message;
        if (event.reason.response) {
            // API exception (ServiceException)
            try {
                const parsed = JSON.parse(event.reason.response);
                message = parsed.error.message;
            } catch (e) {
            }
        }

        if (!message) {
            message = event.reason.message || event.reason;
        }

        onUnhandledError(message);
    } else {
        onUnhandledError("Something broke down");
    }
});
