import {makeAutoObservable, runInAction} from "mobx";
import {apiClient, getApiToken} from "../ApiClient";
import {toast} from "react-toastify";
import {appContext, AppContextStore, UserInfo} from "../AppContext";
import dedent from "dedent";

import {
    CommentObjectType,
    FileUploadFolder,
    InputSchema,
    RecipeSchema,
    RunRecipeRequestSchema,
    ShareLinkSchema,
    SimpleRecipeKind,
    UpdateRecipeSchema,
    VoteResponse,
    WaitForCompletionResponseSchema
} from "../api";
import Uppy from "@uppy/core";
import ImageEditor, {ImageEditorOptions} from "@uppy/image-editor";
import XHR, {XHRUploadOptions} from "@uppy/xhr-upload";
import {CommentListStore} from "./CommentListStore";
import {bonusCreditsDialogStore} from "./BonusCreditsDialogStore";
import {logWebEvent} from "../utils/WebEventLogger";

export enum RecipeType {
    Simple = "simple",
    Comfy = "comfy"
}

export enum ValueKind {
    Text = "TEXT",
    Image = "IMAGE",
}


export interface RecipeParameter {
    name: string
    displayName: string;
    description: string;
    kind: ValueKind;
    order: number;
    value: string;
    hidden: boolean;

    isVideo: boolean;
}

export class Input implements RecipeParameter {
    constructor(init?: Partial<Input>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
            this.initUppyControl();
        }
    }

    isVideo: boolean = false;

    name: string;
    order: number;

    displayName: string;
    description: string;

    kind: ValueKind;
    value: string;
    hidden: boolean;

    uppyImageUploader?: Uppy;

    setValue(value: string) {
        this.value = value;
    }

    setDisplayName(value: string) {
        this.displayName = value;
    }


    setDescription(value: string) {
        this.description = value;
    }

    initUppyControl() {
        if (this.kind === ValueKind.Image) {
            this.uppyImageUploader = new Uppy(
                {
                    restrictions: {
                        minNumberOfFiles: 1,
                        maxNumberOfFiles: 1,
                        allowedFileTypes: ["image/*"],
                        maxFileSize: 10 * 1024 * 1024
                    },
                    onBeforeUpload: (files) => {
                        if (Object.keys(files).length < 1) {
                            alert("Please select an image first!");
                            return false;
                        }
                    }
                })
                .use(XHR, {
                    endpoint: "/api/v1/upload",
                    formData: true,
                    fieldName: "file",
                    headers: (file) => {
                        return {
                            authorization: `Bearer ${getApiToken()}`,
                        };
                    }
                } as XHRUploadOptions)
                .use(ImageEditor, {
                    actions: {
                        revert: false,
                        rotate: false,
                        granularRotate: false,
                        flip: false,
                        zoomIn: false,
                        zoomOut: false,
                        cropSquare: false,
                        cropWidescreen: false,
                        cropWidescreenVertical: false,
                    }
                } as ImageEditorOptions);
        }
    }

    static fromDto(dto: any): Input {
        const result = new Input();
        result.loadFromDto(dto);
        return result;
    }

    loadFromDto(dto: any) {
        Object.assign(this, dto);
        this.initUppyControl();
    }

}

export class Output implements RecipeParameter {
    constructor(init?: Partial<Output>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    name: string;
    order: number;

    displayName: string;
    description: string;
    kind: ValueKind;
    value: string;
    hidden: boolean;

    get isVideo(): boolean {
        if (this.kind === ValueKind.Image && this.value) {
            const extension = this.value.toLowerCase().split('.').pop();
            return extension === "mp4" || extension === "webm" || extension === "avi";
        }

        return false;
    }

    static fromDto(dto: any): Output {
        const result = new Output();
        result.loadFromDto(dto);
        return result;
    }

    loadFromDto(dto: any) {
        Object.assign(this, dto);
    }
}

export enum ResultDisplayStyle {
    // values should match recipes/models.py
    Result = "RESULT",
    Split = "SPLIT",
    SideBySide = "SIDE_BY_SIDE",
}


export enum Visibility {
    Public = "PUBLIC",
    Unlisted = "UNLISTED",
    Private = "PRIVATE",
}

export enum RecipeState {
    Queued = "QUEUED",
    Running = "RUNNING",
    Retrying = "RETRYING",
    Succeeded = "SUCCEEDED",
    Failed = "FAILED",
}

export class SimpleRecipeSettings {
    constructor(init?: Partial<SimpleRecipeSettings>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    kind: SimpleRecipeKind;
    promptTemplate: string;
    preProcessingPromptTemplate?: string;

    setPreProcessingPromptTemplate(value: string) {
        this.preProcessingPromptTemplate = value;
    }

    setPromptTemplate(value: string) {
        this.promptTemplate = value;
    }
}

export class ComfyMetadata {
    constructor(init?: Partial<ComfyMetadata>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    customNodes: string[];
    models: string[];
}


export enum RecipeMode {
    Default,
    Details,
    ApiDetails,
    Edit,
    ResultView
}

export class RecipeStore {
    constructor(init?: Partial<RecipeStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }
    }

    appContext: AppContextStore = appContext;

    id: number;
    name: string;
    state: RecipeState;
    urlSafeName: string
    description: string;
    recipeType: RecipeType;

    templateId: number | undefined;
    template?: RecipeStore;

    shareId: string | undefined;
    thumbnailUrl: string | undefined;
    animatedThumbnailUrl: string | undefined;

    thumbnailRecipe: RecipeStore | undefined;
    remixedFrom: RecipeStore | undefined;

    isDraft: boolean = false
    draftForId: number | undefined

    canRemix: boolean = true

    setCanRemix(value: boolean) {
        this.canRemix = value;
    }

    runCount: number = 0
    viewCount: number = 0

    isOwner: boolean = false
    isTemplateOwner: boolean = false

    simpleRecipeSettings?: SimpleRecipeSettings;

    comfyMetadata?: ComfyMetadata;

    editorPreviewResult?: RecipeStore

    get generatingPreview(): boolean {
        return this.editorPreviewResult?.isRunning;
    }

    setName(value: string) {
        this.name = value;
    }

    setDescription(value: string) {
        this.description = value;
    }

    get isTemplate(): boolean {
        return !this.templateId;
    }

    get canEdit(): boolean {
        return this.isOwner || this.appContext.currentUser?.isSuperuser;
    }

    get canEditTemplate(): boolean {
        return this.isTemplateOwner || this.appContext.currentUser?.isSuperuser;
    }

    showAdvancedSettings: boolean = false;

    toggleAdvancedSettings() {
        this.showAdvancedSettings = !this.showAdvancedSettings;
    }

    resultDisplayStyle: ResultDisplayStyle = ResultDisplayStyle.Result;

    get isFailedRecipeResult() {
        return this.state === RecipeState.Failed;
    }

    get showFailedComfyRecipeMessage() {
        return this.isFailedRecipeResult && this.canEditTemplate && this.recipeType === RecipeType.Comfy;
    }

    get canChangeResultDisplayStyle(): boolean {
        if (!this.visibleInputs) {
            return false;
        }

        if (this.isOwner || this.appContext.currentUser?.isSuperuser) {
            return true;
        }

        if (this.resultInputsShared) {
            return true;
        }

        return false;
    }

    get isRunning() {
        return this.state === RecipeState.Running || this.state === RecipeState.Retrying || this.state === RecipeState.Queued;
    }

    async setResultDisplayStyle(value: ResultDisplayStyle) {
        this.resultDisplayStyle = value;

        if (this.isOwner) {
            await apiClient.patch(`/api/v1/recipes/${this.id}`, {
                "resultDisplayStyle": value
            });
        }
    }

    resultInputsShared: boolean;

    async setResultInputsShared(value: boolean) {
        this.resultInputsShared = value;

        await apiClient.patch(`/api/v1/recipes/${this.id}`, {
            "resultInputsShared": value
        });
    }

    visibility: Visibility = Visibility.Public;

    get visibilityDisplayName(): string {
        switch (this.visibility) {
            case Visibility.Public:
                return "Public"
            case Visibility.Unlisted:
                return "Unlisted"
            case Visibility.Private:
                return "Private"
        }
    }

    async setVisibility(value: Visibility) {
        this.visibility = value;

        await apiClient.patch(`/api/v1/recipes/${this.id}`, {
            "visibility": value
        });
    }


    owner: UserInfo

    upvotes: number = 0;
    downvotes: number = 0;
    myVote: number = 0;

    costCredits: number = 0;

    inputs: Input[] = [];
    outputs: Output[] = [];


    comments?: CommentListStore;

    mode: RecipeMode = RecipeMode.Default;


    get canShowSplitView(): boolean {
        return this.hasVisibleImageOutputAndInput && this.outputs.some(o => !o.isVideo);
    }

    get visibleInputs(): Input[] | null {
        if (!this.inputs) {
            return null;
        }
        return this.inputs.filter(o => !o.hidden);
    }

    get visibleInputsPreferImage(): Input[] | null {
        const inputs = this.visibleInputs;
        if (!inputs) {
            return inputs;
        }

        inputs.sort((a, b) => {
            if (a.kind === ValueKind.Image && b.kind !== ValueKind.Image) {
                return -1;
            }
            if (a.kind !== ValueKind.Image && b.kind === ValueKind.Image) {
                return 1;
            }
            return a.order - b.order;
        });

        return inputs;
    }

    get inputsForBeforeAndAfter(): Input[] | null {
        const visibleInputs = this.visibleInputs;
        if (!visibleInputs) {
            return null;
        }
        return this.inputs.filter(o => o.name && o.name.toLowerCase().indexOf("negat") === -1);
    }

    get hasVisibleImageOutput(): boolean {
        return this.outputs &&
            this.outputs.some(o => o.kind === ValueKind.Image && !o.hidden);
    }

    get hasVisibleImageInput(): boolean {
        return this.inputs &&
            this.inputs.some(o => o.kind === ValueKind.Image && !o.hidden);
    }

    get hasVisibleImageOutputAndInput(): boolean {
        return this.hasVisibleImageOutput && this.hasVisibleImageInput;
    }

    get visibleOutputs(): Output[] {
        return this.outputs.filter(o => !o.hidden);
    }


    sharingDialogVisible: boolean = false;

    async showSharingDialog() {
        this.ensurePersonalShareUrl().then();
        this.sharingDialogVisible = true;

        logWebEvent("web.sharingDialog.show", "Displayed sharing dialog", {}).then();
    }

    hideSharingDialog() {
        this.sharingDialogVisible = false;
    }

    personalShareUrl?: string = null;

    get shareUrl(): string {
        const baseUrl = window.location.origin

        if (this.personalShareUrl) {
            return `${this.personalShareUrl}`;
        } else if (this.shareId) {
            return `${baseUrl}/r/${this.shareId}`;
        } else {
            return `${baseUrl}/recipes/${this.id}-${this.urlSafeName}`;
        }
    }

    generatingShareUrl: boolean = false;

    async ensurePersonalShareUrl() {
        if (this.personalShareUrl || this.generatingShareUrl || !this.appContext.currentUser) {
            return;
        }
        this.generatingShareUrl = true;
        try {
            let url = `/api/v1/recipes/${this.id}/share-link`;

            if (this.shareId) {
                url += "?share_id=" + this.shareId;
            }

            const response = await apiClient.get<ShareLinkSchema>(url);
            runInAction(() => {
                this.personalShareUrl = response.url;
            });
        } finally {
            runInAction(() => {
                this.generatingShareUrl = false;
            });
        }
    }

    async copyShareUrlToClipboard() {
        await navigator.clipboard.writeText(this.shareUrl);
        toast("Link copied.", {type: "info"});
    }

    remixInfoDialogVisible: boolean = false;

    async showRemixInfoDialog() {
        this.remixInfoDialogVisible = true;
    }

    hideRemixInfoDialog() {
        this.remixInfoDialogVisible = false;
    }


    get isComfyRecipe() {
        return this.recipeType === RecipeType.Comfy;
    }

    static fromDto(dto: RecipeSchema, mode: RecipeMode, appContext?: AppContextStore): RecipeStore {
        const result = new RecipeStore();
        if (appContext) {
            result.appContext = appContext;
        }
        result.loadFromDto(dto, mode);
        return result;
    }

    loadFromDto(dto: RecipeSchema, mode: RecipeMode) {
        Object.assign(this, dto);

        this.mode = mode;

        this.owner = UserInfo.fromDto(dto.owner);

        if (dto.inputs) {
            this.inputs = dto.inputs.map(Input.fromDto);
        }
        if (dto.outputs) {
            this.outputs = dto.outputs.map(Output.fromDto);
        }

        if (dto.template) {
            this.template = RecipeStore.fromDto(dto.template, RecipeMode.Default, this.appContext);
        }

        if (dto.thumbnailRecipe) {
            this.thumbnailRecipe = RecipeStore.fromDto(dto.thumbnailRecipe, RecipeMode.Default, this.appContext);
        }

        if (dto.remixedFrom) {
            this.remixedFrom = RecipeStore.fromDto(dto.remixedFrom, RecipeMode.Default, this.appContext);
        }

        if (this.appContext.currentUser) {
            this.isOwner = appContext.currentUser.id === this.owner.id;
            this.isTemplateOwner = this.template && this.template.isOwner
        }

        if (dto.comments) {
            this.comments = CommentListStore.fromDto(CommentObjectType.RECIPE, this.id, dto.comments);
        }

        if (dto.simpleRecipeSettings) {
            this.simpleRecipeSettings = new SimpleRecipeSettings(dto.simpleRecipeSettings);
        }

        if (dto.comfyMetadata) {
            this.comfyMetadata = new ComfyMetadata(dto.comfyMetadata);
        }

        if ((mode == RecipeMode.Details || mode == RecipeMode.ApiDetails) && this.inputs && this.inputs.length > 0) {
            for (const [name, value] of new URLSearchParams(window.location.search)) {
                const input = this.inputs.find(i => i.name === name)
                if (input) {
                    input.value = value;
                }
            }
        }

        if (mode === RecipeMode.ResultView && this.isRunning) {
            this.waitForReady();
        }
    }

    async reloadRecipeResultFromApi() {
        if (this.mode !== RecipeMode.ResultView) {
            throw new Error("Can only reload in result view mode");
        }

        const response = await apiClient.get<RecipeSchema>(`/api/v1/recipe-results/${this.shareId}`);
        this.loadFromDto(response, this.mode);
    }


    get url(): string {
        if (this.shareId) {
            return `/r/${this.shareId}`;
        } else {
            return `/recipes/${this.id}-${this.urlSafeName}`;
        }
    }

    get templateUrl() {
        return `/recipes/${this.templateId}-${this.urlSafeName}`
    }

    get apiPageUrl(): string {
        return this.url + "/api";
    }

    get rerunRecipeUrl(): string {
        let url = this.templateUrl
        if (this.inputs) {
            const textInputs = this.inputs.filter(i => i.kind === ValueKind.Text)
            if (textInputs.length > 0) {
                url += "?"
                url += textInputs.map(i => `${encodeURIComponent(i.name)}=${encodeURIComponent(i.value)}`).join("&")
            }

        }
        return url;
    }

    get downloadWorkflowUrl(): string | null {
        if (this.recipeType === RecipeType.Comfy && (this.canRemix || this.isOwner)) {
            return `/comfy/recipes/${this.id}/download`;
        }
        return null;
    }

    remixing: boolean = false;

    async remix() {
        this.remixing = true;

        try {
            const remixDto = await apiClient.post<RecipeSchema>(`/api/v1/recipes/${this.id}/remix`, {});
            const remixedStore = RecipeStore.fromDto(remixDto, RecipeMode.Default, this.appContext);

            if (this.recipeType === RecipeType.Comfy) {
                window.location.href = remixedStore.waitForProvisioningUrl;
            } else {
                window.location.href = remixedStore.editPageUrl;
            }
        } catch (e) {
            runInAction(() => {
                this.remixing = false;
            })

            throw e;
        }
    }

    get editPageUrl(): string | null {
        if (!this.isTemplate) {
            return null;
        }

        if (this.recipeType === RecipeType.Comfy) {
            return `/comfy/recipes/${this.id}/update-draft`;
        } else {
            return `/recipes/${this.id}/update`;
        }
    }

    get waitForProvisioningUrl(): string | null {
        if (this.recipeType === RecipeType.Comfy) {
            return `/comfy/recipes/${this.id}/wait-for-provisioning`;
        } else {
            return null
        }
    }

    get displayUpvotePercentage(): number | undefined {
        const total = this.upvotes + this.downvotes
        if (total == 0) {
            return undefined
        }
        const percentage = Math.round((this.upvotes / total) * 100)

        if (percentage < 50) {
            return undefined
        }

        return percentage
    }


    async upVote() {
        if (!await appContext.ensureSignedIn()) {
            return;
        }

        let newVote = (this.myVote === 1) ? 0 : 1;
        await this.setVote(newVote);
    }

    async downVote() {
        if (!await appContext.ensureSignedIn()) {
            return;
        }

        let newVote = (this.myVote === -1) ? 0 : -1;
        await this.setVote(newVote);
    }

    async setVote(value: number) {
        this.myVote = value;
        const response = await apiClient.post<VoteResponse>(
            `/api/v1/recipes/${this.id}/vote`, {"value": value});

        runInAction(() => {
            this.upvotes = response.upvotes;
            this.downvotes = response.downvotes;
        });
    }

    async delete() {
        if (!await appContext.ensureSignedIn()) {
            return;
        }
        if (confirm("Are you sure you want to delete this?") === false) {
            return;
        }

        await apiClient.delete(`/api/v1/recipes/${this.id}`);

        if (this.templateId) {
            window.location.href = this.templateUrl;
        } else {
            window.location.href = "/recipes";
        }
    }

    async setAsTemplateThumbnail(): Promise<void> {
        const templateDto = await apiClient.post<RecipeSchema>(`/api/v1/recipes/${this.id}/set-as-template-thumbnail`);

        const template = RecipeStore.fromDto(templateDto, RecipeMode.Default, this.appContext);
        window.location.href = template.url;
    }

    private recipeCompletionTimer: any;

    private waitForReady() {
        if (!this.isRunning) {
            return;
        }

        setTimeout(() => {
            this.waitForRecipeCompletion();
            // delaying first run in case to slow the infinite loop if it happens due to caching error
        }, 5000);

        this.recipeCompletionTimer = setInterval(() => {
            this.waitForRecipeCompletion();
        }, 60000);
    }

    private async waitForRecipeCompletion() {
        const response = await apiClient.post<WaitForCompletionResponseSchema>(
            `/api/v1/recipes/${this.id}/wait-for-completion?timeout_seconds=55`,
        );

        if (response.completed) {
            clearInterval(this.recipeCompletionTimer);
            await this.reloadRecipeResultFromApi();
        }
    }

    get haveEnoughCreditsToRun(): boolean {
        if (!this.appContext?.currentUser) {
            return true; // new users get credits
        }

        return this.appContext.currentUser.credits >= this.costCredits;
    }

    get recipesPerDay(): number {
        if (this.costCredits <= 0) {
            return 100;
        }
        return this.appContext.creditsPerDay / this.costCredits;
    }

    get recipeRunsRemaining(): number {
        if (!this.appContext.currentUser) {
            return 100;
        }
        if (this.costCredits <= 0) {
            return 100;
        }

        return Math.floor(this.appContext.currentUser.credits / this.costCredits);
    }

    get showLowCreditsWarning(): boolean {
        if (!this.haveEnoughCreditsToRun) {
            return false;
        }

        return (this.recipeRunsRemaining / this.recipesPerDay) <= 0.75;
    }

    isStartingRecipe: boolean = false;

    get canCreateNow(): boolean {
        return !this.isStartingRecipe;
    }


    async runRecipe() {
        if (!await appContext.ensureSignedIn()) {
            return;
        }

        if (!this.haveEnoughCreditsToRun) {
            bonusCreditsDialogStore.showDialog();
            return;
        }

        this.isStartingRecipe = true;
        try {
            const inputs = {};

            for (const input of this.inputs) {

                if (input.kind === ValueKind.Image && input.uppyImageUploader) {

                    await new Promise((resolve) => {
                        try {
                            const imageEditor = input.uppyImageUploader.getPlugin("ImageEditor") as any;
                            input.uppyImageUploader.once("file-editor:complete", (file) => {
                                resolve(file);
                            });
                            imageEditor.save();
                        } catch (e) {
                            console.log("Cannot save editor image", e);
                            resolve(null);
                        }
                    });

                    input.uppyImageUploader.setMeta({
                        recipeId: this.id,
                        folder: FileUploadFolder.RECIPE_INPUTS
                    });

                    let files = input.uppyImageUploader.getFiles();
                    if (files.length > 0 && files[0].response?.status >= 400) {
                        input.uppyImageUploader.retryAll();
                    }

                    const result = await input.uppyImageUploader.upload();

                    if (result.failed.length > 0) {
                        const firstFailed = result.failed[0];
                        toast(`Failed to upload image for: '${input.displayName}'. Reason: ${firstFailed.error}`,
                            {type: "error"});
                        return;
                    }

                    files = input.uppyImageUploader.getFiles();

                    const url = files[0].response.body["url"]
                    inputs[input.name] = url;

                } else {
                    inputs[input.name] = input.value || "";
                }
            }

            const request: RunRecipeRequestSchema = {
                inputs: inputs
            }

            if (this.mode === RecipeMode.Edit) {
                await this.saveChanges();
                const instance = await apiClient.post<RecipeSchema>(`/api/v1/recipes/${this.id}/run`, request);
                this.editorPreviewResult = RecipeStore.fromDto(instance, RecipeMode.ResultView, this.appContext);
                this.appContext.currentUser.credits -= this.costCredits;
            } else {
                const instance = await apiClient.post<RecipeSchema>(`/api/v1/recipes/${this.id}/run`, request);
                const instanceStore = RecipeStore.fromDto(instance, RecipeMode.Default, this.appContext);
                window.location.href = instanceStore.url;
            }

        } finally {
            runInAction(() => {
                this.isStartingRecipe = false;
            });
        }
    }

    async saveChanges() {
        if (this.mode !== RecipeMode.Edit) {
            throw new Error("Cannot save changes in non-edit mode");
        }

        let schema: UpdateRecipeSchema = {
            name: this.name,
            description: this.description,
            simpleRecipeSettings: {
                promptTemplate: this.simpleRecipeSettings?.promptTemplate,
                preProcessingPromptTemplate: this.simpleRecipeSettings?.preProcessingPromptTemplate,
            },
            canRemix: this.canRemix,
            inputs: this.inputs.map(i => {
                return {
                    name: i.name,
                    displayName: i.displayName,
                    description: i.description,
                    kind: i.kind as any,
                    order: i.order,
                    value: i.kind === ValueKind.Text ? (i.value || "") : "",
                    hidden: i.hidden,
                } as InputSchema
            })
        }

        const result = await apiClient.patch(`/api/v1/recipes/${this.id}`, schema);
    }

    async saveChangesAndGoToDetails() {
        await this.saveChanges();
        window.location.href = this.url;
    }

    async publishAndGoToDetails() {
        await this.saveChanges();

        const templateDto = await apiClient.post<RecipeSchema>(`/api/v1/recipes/${this.id}/publish`);
        const template = RecipeStore.fromDto(templateDto, RecipeMode.Default, this.appContext);

        window.location.href = template.url;
    }


    get apiBlockCurl(): string {
        let inputsBlock = "";
        for (const input of this.inputs) {
            let value = input.value;
            if (input.kind === ValueKind.Image) {
                value = "https://upload.wikimedia.org/wikipedia/commons/d/d4/1903-Hadersleben-Frau_mit_Klemmer.jpg"
            }
            if (inputsBlock) {
                inputsBlock += ","
            }
            inputsBlock += `\n                   "${input.name}": "${value}"`
        }

        let block = dedent(`
            curl -s -L -X POST \\\\\n
               -H "Authorization: Bearer $YOUML_API_TOKEN" \\\\\n
               -H "Content-Type: application/json" \\\\\n
               -d $'{
                 "inputs": {${inputsBlock}
                 }
               }' \\\\\n
               ${window.location.origin}/api/v1/recipes/${this.id}/run?wait=600`)

        return block;
    }


    get apiBlockPowershell(): string {
        let inputsBlock = "";
        for (const input of this.inputs) {
            let value = input.value;
            if (input.kind === ValueKind.Image) {
                value = "https://upload.wikimedia.org/wikipedia/commons/d/d4/1903-Hadersleben-Frau_mit_Klemmer.jpg"
            }
            if (inputsBlock) {
                inputsBlock += ","
            }
            inputsBlock += `\n                    ${input.name} = "${value}"`
        }

        let block = dedent(`
            $RecipeParameters = @{
                Body = ConvertTo-Json @{
                  inputs = @{${inputsBlock}
                  }
                }
                Uri = "${window.location.origin}/api/v1/recipes/${this.id}/run?wait=600"
                Method = "POST"
                Headers = @{
                    "Authorization" = "Bearer \${env:YOUML_API_TOKEN}"
                    "Content-Type" = "application/json"
                }
                OperationTimeoutSeconds = 90
                MaximumRedirection = 50
            }

            Invoke-RestMethod @RecipeParameters
            `)

        return block;
    }

    get apiBlockJavascript(): string {
        let inputsBlock = "";
        for (const input of this.inputs) {
            let value = input.value;
            if (input.kind === ValueKind.Image) {
                value = "https://upload.wikimedia.org/wikipedia/commons/d/d4/1903-Hadersleben-Frau_mit_Klemmer.jpg"
            }
            if (inputsBlock) {
                inputsBlock += ","
            }
            inputsBlock += `\n                        "${input.name}": "${value}"`
        }

        let block = dedent(`
                const apiToken = "XXXX";
                const request = {
                    "inputs": {${inputsBlock}
                    }
                };

                const result = await fetch(
                    "${window.location.origin}/api/v1/recipes/${this.id}/run?wait=600",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": \`Bearer \${apiToken}\`
                        },
                        body: JSON.stringify(request)
                    });

                console.log(await result.json());
            `);
        return block;
    }


    get apiBlockPython(): string {
        let inputsBlock = "";
        for (const input of this.inputs) {
            let value = input.value;
            if (input.kind === ValueKind.Image) {
                value = "https://upload.wikimedia.org/wikipedia/commons/d/d4/1903-Hadersleben-Frau_mit_Klemmer.jpg"
            }
            if (inputsBlock) {
                inputsBlock += ","
            }
            inputsBlock += `\n                    "${input.name}": "${value}"`
        }

        let block = dedent(`
            api_token = "XXXX"
            request = {
                "inputs": {${inputsBlock}
                }
            }

            response = requests.post(
                "${window.location.origin}/api/v1/recipes/${this.id}/run?wait=600",
                headers={
                    "Content-Type": "application/json",
                    "Authorization": f"Bearer {api_token}"
                },
                json=request,
                allow_redirects=True,
                timeout=90)

            print(response.json())
            `);
        return block;
    }
}


