import {computed, makeAutoObservable, makeObservable, observable} from "mobx"
import {CurrentUserContext, SignInModalStore} from "./components/SignInModalStore";
import {setApiToken} from "./ApiClient";
import {UserInfoSchema} from "./api";
import {EventName, reportTrackingEvent} from "./utils/Trackers";

export class UserInfo {
    constructor(init?: Partial<UserInfo>) {
        if (init) {
            Object.assign(this, init);
        }

        makeObservable(this, {
            username: observable,
            profileUrl: computed,
        })
    }

    id: number;
    username: string;

    get profileUrl() {
        return `/users/${encodeURIComponent(this.username)}`;
    }

    static fromDto(dto: UserInfoSchema): UserInfo {
        const result = new UserInfo()
        result.loadFromDto(dto);
        return result
    }

    loadFromDto(dto: UserInfoSchema) {
        this.id = dto.id;
        this.username = dto.username;
    }
}

export class CurrentUserInfo extends UserInfo {
    constructor(init?: Partial<CurrentUserInfo>) {
        super(init);

        makeObservable(this, {
            email: observable,
            isSuperuser: observable,
            credits: observable,
        })
    }

    email: string;
    isSuperuser: boolean = false;
    credits: number;

    static fromDto(dto: any): CurrentUserInfo {
        const result = new CurrentUserInfo()
        result.loadFromDto(dto);
        return result
    }

    loadFromDto(dto: any) {
        super.loadFromDto(dto);
        this.email = dto.email;
        this.isSuperuser = dto.isSuperuser;
        this.credits = dto.credits;
    }
}


export class AppContextStore {

    constructor(init?: Partial<AppContextStore>) {
        makeAutoObservable(this);
        if (init) {
            Object.assign(this, init);
        }


    }

    currentUser: CurrentUserInfo | undefined;

    signInDialog: SignInModalStore = new SignInModalStore();

    creditsPerDay: number = 250; // keep in sync with YOUML_CREDITS_PER_DAY

    async ensureSignedIn(): Promise<boolean> {
        if (this.currentUser) {
            return true;
        }

        const result = await this.signInDialog.startInteractiveSignIn();
        if (result) {
            this.currentUser = result.user;
            setApiToken(result.apiToken);

            await reportTrackingEvent(EventName.SignIn);

            return true;
        } else {
            return false;
        }
    }

}

export const appContext = new AppContextStore();


export function setCurrentUserContext(context: CurrentUserContext) {
    appContext.currentUser = CurrentUserInfo.fromDto(context.user);
    setApiToken(context.apiToken);
}
