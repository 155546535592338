import * as React from "react";
import {observer} from "mobx-react";
import {RecipeStore} from "./RecipeStore";


export const VotingComponent = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div>
        <div className="btn-group" role="group">
            <button className={`btn ${store.myVote == 1 ? "btn-light" : "btn-secondary"} px-3`}
                    onClick={() => store.upVote()}>
                <span><i className="fas fa-thumbs-up"></i></span>

                {store.upvotes > 0 &&
                    <span className="ms-2">{store.upvotes}</span>
                }
            </button>

            <button className={`btn ${store.myVote == -1 ? "btn-light" : "btn-secondary"} px-3`}
                    onClick={() => store.downVote()}>
                <span><i className="fas fa-thumbs-down"></i></span>
            </button>
        </div>
    </div>);
})

