import * as React from "react";
import {observer} from "mobx-react";
import {RecipeStore, Visibility} from "./RecipeStore";
import {Button, ButtonGroup, Dropdown, DropdownButton, Form, InputGroup} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    VKIcon,
    VKShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from "react-share";

export const ShareButtonsBlock = observer((props: { url: string, imageUrl?: string, title: string }) =>
    <div className="sh-buttons">
        <FacebookShareButton url={props.url}>
            <FacebookIcon size={32} round/>
        </FacebookShareButton>

        {/*<FacebookMessengerShareButton url={props.url}>*/}
        {/*    <FacebookMessengerIcon size={32} round/>*/}
        {/*</FacebookMessengerShareButton>*/}

        <TwitterShareButton url={props.url} title={props.title}>
            <XIcon size={32} round/>
        </TwitterShareButton>


        <TelegramShareButton url={props.url} title={props.title}>
            <TelegramIcon size={32} round/>
        </TelegramShareButton>

        <WhatsappShareButton url={props.url} title={props.title} separator=":: ">
            <WhatsappIcon size={32} round/>
        </WhatsappShareButton>

        <LinkedinShareButton url={props.url}>
            <LinkedinIcon size={32} round/>
        </LinkedinShareButton>

        <PinterestShareButton url={props.url} media={props.imageUrl}>
            <PinterestIcon size={32} round/>
        </PinterestShareButton>

        <VKShareButton url={props.url} image={props.imageUrl}>
            <VKIcon size={32} round/>
        </VKShareButton>

        <RedditShareButton url={props.url} title={props.title}
                           windowWidth={660}
                           windowHeight={460}>
            <RedditIcon size={32} round/>
        </RedditShareButton>

        <EmailShareButton
            url={props.url} title={props.title}
            body="Check this out!">
            <EmailIcon size={32} round/>
        </EmailShareButton>

        <WeiboShareButton
            url={props.url} title={props.title}
            image={props.imageUrl}>
            <WeiboIcon size={32} round/>
        </WeiboShareButton>


    </div>
)
export const RecipeSharingDialog = observer((props: { store: RecipeStore }) => {
    const store = props.store;
    return (<div className="mt-5 recipe-sharing-dialog">
        <Modal show={store.sharingDialogVisible}
               onHide={() => store.hideSharingDialog()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="d-flex align-items-center">
                        <div>Share</div>
                        {store.generatingShareUrl &&
                            <div className="spinner-border spinner-border-sm text-primary ms-2"
                                 role="status">
                            </div>}
                    </div>

                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    <InputGroup>
                        <Form.Control
                            readOnly={true}
                            value={store.shareUrl}
                        />
                        <Button variant="outline-secondary"
                                onClick={() => store.copyShareUrlToClipboard()}>
                            Copy
                        </Button>
                    </InputGroup>
                </div>
                <div className="mb-4">
                    <ShareButtonsBlock
                        url={store.shareUrl}
                        title={store.name}
                        imageUrl={store.thumbnailUrl}
                    />
                </div>

                {store.canEdit && <>
                    {!store.isTemplate &&
                        <div className="mb-4">
                            <Form.Label>
                                What to share
                            </Form.Label>

                            <Form.Check
                                id="share-result-only-checkbox"
                                type="radio"
                                label="Share Result Only"
                                checked={!store.resultInputsShared}
                                onClick={() => store.setResultInputsShared(false)}
                            />

                            <Form.Check
                                id="share-before-after-checkbox"
                                type="radio"
                                label="Share Before & After"
                                checked={store.resultInputsShared}
                                onClick={() => store.setResultInputsShared(true)}
                            />

                        </div>}

                    <div className="mb-3">
                        <div>
                            <Form.Label>
                                Visibility
                            </Form.Label>
                        </div>
                        <DropdownButton as={ButtonGroup} title={store.visibilityDisplayName}
                                        id="recipe-result-visibility"
                                        variant="secondary">
                            <Dropdown.Item eventKey="public" onClick={() => store.setVisibility(Visibility.Public)}>
                                Public - anyone can view
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="unlisted" onClick={() => store.setVisibility(Visibility.Unlisted)}>
                                Unlisted - only people with the link can view
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="private" onClick={() => store.setVisibility(Visibility.Private)}>
                                Private - only you can view
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </>}
            </Modal.Body>
        </Modal>
    </div>);
})

